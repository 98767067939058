import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import { FiMic, FiMicOff, FiVideo, FiVideoOff } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { IoCameraReverseOutline } from "react-icons/io5";
import { ChatScreen } from "./ChatScreen";
import { PrescriptionScreen } from "./PrescriptionScreen";
import { IoMdArrowBack } from "react-icons/io";
import { AppDataContext } from "../../context/AppContext";
import {
  createCallLog,
  getAgoraToken,
  getItemsId,
  getTypeId,
} from "../../js/convo";
import { createCookie, getCookie } from "../../js/cookies";
import AdminHeader from "../admin/panels/components/PanelMainHeader";
import { useBeforeunload } from "react-beforeunload";
import { SocketContext } from "../../context/socket";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";


export const OutgoingVideoCallRoom = () => {
  const { roomId } = useParams();
  const { lm_reference_id, doctor, patient, reference_id } = useLocation().state;

  const [chatSection, setChatSection] = useState("chat");

  const [token, setToken] = useState(null);

  const [users, setUsers] = useState([]);

  const [startMeet, setStartMeet] = useState(false);

  const [inCall, setInCall] = useState(false);

  const [mobileSection, setMobileSection] = useState("video");

  const nav = useNavigate();

  const appContext = useContext(AppDataContext);

  const socket = useContext(SocketContext);

  const [done, setDone] = useState(false);

  const [departments, setDepartments] = useState([]);

  const [saved, setSaved] = useState(false);

  const [itemMasters, setItemMasters] = useState([]);

  const [typeMaster, setTypeMaster] = useState([]);

  // time stuffs

  const [timer, setTimer] = useState(["00", "00", "00"]);

  const [startTime, setStartTime] = useState(new Date());

  const [presId, setPresId] = useState(null);

  const [age, setAge] = useState(0);

  var pres_id = null;

  var saveDone = false;

  const [chatHeight, setChatHeight] = useState(0);

  const [joined, setJoined] = useState(false);

  const [ringTime, setRingTime] = useState(null);

  const [trackState, setTrackState] = useState({ video: true, audio: true });

  const logId = Math.floor(Math.random() * 1000000000);

  const leaveCallId = useRef(Math.floor(Math.random() * 1000000000));

  const [unread, setUnread] = useState(false);

  const section = useRef("chat");

  // ALL OUR HANDLERS
  const handleClose = () => { };

  const patientJoined = useRef(false);

  const handleSaveChange = (value) => {
    setSaved(value);
  };





  const setCallLogs = async () => {
    var h = startTime.getHours();
    var m = startTime.getMinutes();
    var s = startTime.getSeconds();
    if (h < 10) h = "0" + h;
    if (m < 10) m = "0" + m;
    if (s < 10) s = "0" + s;
    var st = h + ":" + m + ":" + s;
    console.log(st);
    var endTime = new Date();
    h = endTime.getHours();
    m = endTime.getMinutes();
    s = endTime.getSeconds();
    if (h < 10) h = "0" + h;
    if (m < 10) m = "0" + m;
    if (s < 10) s = "0" + s;
    var et = h + ":" + m + ":" + s;
    console.log(et);
    var rt = endTime;
    console.log(patient);
    var queue = patient.queueStartTime;
    var qD;
    var d = endTime.getDate();
    var m = endTime.getMonth() + 1;
    var y = endTime.getFullYear();
    if (d < 10) d = "0" + d;
    if (m < 10) m = "0" + m;
    qD = m + "/" + d + "/" + y;
    queue = new Date(qD + " " + queue);
    var duration = Math.abs(queue - startTime) / 1000;
    var hours = Math.floor(duration / 3600);
    duration %= 3600;
    var minutes = Math.floor(duration / 60);
    var seconds = Math.floor(duration % 60);
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;
    rt = hours + ":" + minutes + ":" + seconds;
    console.log(rt);
    duration = Math.abs(endTime - startTime) / 1000;
    hours = Math.floor(duration / 3600);
    duration %= 3600;
    minutes = Math.floor(duration / 60);
    seconds = Math.floor(duration % 60);
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;
    duration = hours + ":" + minutes + ":" + seconds;
    console.log(duration);
    var map = {
      id: leaveCallId.current,
      status: patientJoined ? "Outgoing - Connected" : "Outgoing - Unconnected",
      patient_name: patient.name,
      patient_id: patient.lm_reference_id,
      ring_time: rt,
      start_time: st,
      end_time: et,
      duration: duration,
      date: new Date(),
      room_id: roomId,
      lm_reference_id: doctor.LM_REFERENCE_ID,
      lm_under_group: doctor.LM_UNDER_GROUP,
      lm_main_id: doctor.LM_MAIN_ID,
      doctor_id: doctor.LM_REFERENCE_ID,
      doctor_name: doctor.LM_NAME,
      prescription_id: appContext.currentPrescription.prescription_id,
      prescription_uploaded: false,
    };
    await createCallLog(map);
  };

  const leaveChannel = async () => {
    console.log(patientJoined.current)
    if (patientJoined.current) {
      console.log("Leaving channel patient came===================>");
      clearInterval(interval.current);
      await setCallLogs();
      console.log(patient.lm_reference_id);
      appContext.setInCall(false);
      if (!saved) {
        var pres = appContext.currentPrescription;
        createCookie("prescription", JSON.stringify(pres), 5);
      }
      setStartMeet(false);
      setInCall(false);
      if (zc !== null) {
        zc.destroy();
      }
      nav(-1)
    } else {
      endMissedCall(true, '', false);
    }
  };

  const updatePrescriptionId = (id) => {
    pres_id = id;
    setPresId(id);
  };

  useEffect(() => {
    if (document.getElementById("initial-header")) {
      setChatHeight(document.getElementById("initial-header").clientHeight);
    }
  });

  const endMissedCall = async (sendCallLog, d_id, fromSocket) => {
    console.log("Ending call==================>");
    var h = startTime.getHours();
    var m = startTime.getMinutes();
    var s = startTime.getSeconds();
    if (h < 10) h = "0" + h;
    if (m < 10) m = "0" + m;
    if (s < 10) s = "0" + s;
    var st = h + ":" + m + ":" + s;
    var endTime = new Date();
    h = endTime.getHours();
    m = endTime.getMinutes();
    s = endTime.getSeconds();
    if (h < 10) h = "0" + h;
    if (m < 10) m = "0" + m;
    if (s < 10) s = "0" + s;
    var et = h + ":" + m + ":" + s;
    var rt = endTime;
    var duration = Math.abs(rt - startTime) / 1000;
    var hours = Math.floor(duration / 3600);
    duration %= 3600;
    var minutes = Math.floor(duration / 60);
    var seconds = Math.floor(duration % 60);
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;
    rt = hours + ":" + minutes + ":" + seconds;
    duration = Math.abs(endTime - startTime) / 1000;
    hours = Math.floor(duration / 3600);
    duration %= 3600;
    minutes = Math.floor(duration / 60);
    seconds = Math.floor(duration % 60);
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;
    duration = hours + ":" + minutes + ":" + seconds;
    var map = {
      id: d_id === '' ? leaveCallId.current : d_id,
      status: patientJoined.current ? "Outgoing - Connected" : "Outgoing - Unconnected",
      patient_name: patient.name,
      patient_id: patient.lm_reference_id,
      ring_time: rt,
      start_time: st,
      end_time: et,
      duration: duration,
      date: new Date(),
      room_id: roomId,
      lm_reference_id: doctor.LM_REFERENCE_ID,
      lm_under_group: doctor.LM_UNDER_GROUP,
      lm_main_id: doctor.LM_MAIN_ID,
      doctor_id: doctor.LM_REFERENCE_ID,
      doctor_name: doctor.LM_NAME,
      prescription_id: null,
      prescription_uploaded: false,
    };
    await createCallLog(map);
    if (sendCallLog) {
        socket.emit("onEndVideoCallToRoom", {
          call: map,
          room: reference_id,
          toPatient: true,
          sendCallLog: true,
        });
    }
    if (!fromSocket) {
      socket.emit("onEndVideoCallToRoom", {
        call: map,
        room: reference_id,
        toPatient: true,
        sendCallLog: false,
      });
    }
    clearInterval(interval.current);
    appContext.setInCall(false);
    socket.emit("leaveRoom", {
      dm_id: doctor.LM_REFERENCE_ID,
      room: roomId,
    })
    socket.emit("signout", {
      dm_id: doctor.LM_REFERENCE_ID
    })
    setStartMeet(false);
    setInCall(false);
    if (zc !== null) {
      zc.destroy();
    }
    nav(-1)
  };

  const interval = useRef(null);

  useEffect(() => {
    socket.emit("joinRoom", {
      room: roomId,
      dm_id: doctor.LM_REFERENCE_ID,
    });
    console.log("Socket is initialized===================>");
    socket.on("endVideoCall", (data) => {
      console.log("End video call=====================>sdasdsadsa")
      console.log(data)
      endMissedCall(data.sendCallLog, data.d_id, true)
    });

    

    socket.on("messageToRoom", (data) => {
      var msg = data.msg;
      if (msg.sender_id !== doctor.LM_REFERENCE_ID) {
        if(section.current !== "chat"){
          setUnread(true);
        }
        var ids = [];
        appContext["messages"].forEach((message) => {
          ids.push(message.message_id);
        });
        if (!ids.includes(msg.message_id)) {
          appContext.addMessage(msg);
        }
      }
    });

    return function cleanup() {
      socket.removeListener('messageToRoom');
    }
  }, []);

  useEffect(() => {
    interval.current = setInterval(() => {
      if(!patientJoined.current){
        endMissedCall(true, '', false);
      }
    }, 60000);
  }, []);

  var zc = null;

  const myMeeting = useCallback((element) => {
    console.log("myMeeting", element);
    const appId = 1516978750;
    const serverSecret = "0202ff61b9255174081f3364bb4b964c";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appId, serverSecret, roomId, Date.now().toString(), doctor.LM_NAME);
    console.log(kitToken + '====================>')
    zc = ZegoUIKitPrebuilt.create(kitToken);
    console.log(zc + '====================>')
    zc.joinRoom({
      container: element,
      turnOnMicrophoneWhenJoining: true,
      turnOnCameraWhenJoining: true,
      showLayoutButton: true,
      showMyCameraToggleButton: true,
      showMyMicrophoneToggleButton: true,
      showAudioVideoSettingsButton: false,
      showScreenSharingButton: false,
      showTextChat: false,
      showUserList: false,
      showLeavingView: false,
      showRoomDetailsButton: false,
      maxUsers: 2,
      layout: "Auto",
      onJoinRoom: (roomID, userList) => {
        console.log("onJoinRoom", roomID, userList);
      },
      onLeaveRoom: (roomID) => {
        console.log("onLeaveRoom", roomID);
        leaveChannel();
      },
      onUserJoin: (roomID, userList) => {
        console.log("onUserJoin", roomID, userList);
        clearInterval(interval.current);
        if (patientJoined.current === false) {
          setRingTime(new Date());
          patientJoined.current = true;
        }

      },
      showPreJoinView: false,
      onUserLeave: (roomID, userList) => {
        console.log("onUserLeave", roomID, userList);
      },
      showLayoutButton: false,
      scenario: {
        mode: ZegoUIKitPrebuilt.OneONoneCall,
      },
      showScreenSharingButton: false,

    })


  });

  const videoRef = useRef(null);




  useEffect(() => {
    if (videoRef.current) {
      var element = videoRef.current;
      myMeeting(element);
    }
    if (!appContext.currentPrescription.prescription_id) {
      var presId = Math.floor(Math.random() * 1000000000);
      appContext.changePrescription("prescription_id", presId);
      appContext.changePrescription("lm_reference_id", patient.lm_reference_id);
      appContext.changePrescription("print_chief", false);
      appContext.changePrescription("print_examination", false);
      appContext.changePrescription("print_history", false);
      appContext.changePrescription("print_advice", false);
      appContext.changePrescription(
        "date_time",
        new Date().toLocaleDateString()
      );
      appContext.changePrescription("doctor_id", doctor.LM_REFERENCE_ID);
      appContext.changePrescription("doctor_name", doctor.LM_NAME);
      appContext.changePrescription("patient_id", patient.lm_reference_id);
      appContext.changePrescription("patient_name", patient.name);
      appContext.changePrescription("prescriptions", []);
      appContext.changePrescription("investigations", []);
    }
    var hour = parseInt(timer[0]);
    var minute = parseInt(timer[1]);
    var second = parseInt(timer[2]);
    var interval = setInterval(() => {
      second++;
      if (second === 60) {
        second = 0;
        minute++;
      }
      if (minute === 60) {
        minute = 0;
        hour++;
      }
      if (hour.toString().length === 1) {
        hour = "0" + hour;
      }
      if (minute.toString().length === 1) {
        minute = "0" + minute;
      }
      if (second.toString().length === 1) {
        second = "0" + second;
      }
      setTimer([hour, minute, second]);
    }, 1000);
  }, []);

  const getDepartmentAndTest = async () => {
    var res = await getItemsId();
    if (res.success) {
      var t = res.itemMaster.filter((item) => item.IS_ACTIVE === true);
      setItemMasters(t);
    }
    var res2 = await getTypeId();
    if (res2.success) {
      var t1 = res2.typeMaster.filter((item) => item.IS_ACTIVE === true);
      var dpt_t = t1.filter((item) => item.MASTER_NAME === "Department");
      setTypeMaster(t1);
      var deps = [];
      for (var i = 1; i < dpt_t.length; i++) {
        deps.push(dpt_t[i].TYPE_NAME);
      }
      // get all TYPE_NAME which have MASTER_NAME as "Department"
      setDepartments(deps);
    }
  };


  useEffect(() => {
    if (!done) {
      console.log("init ready");
      appContext.setMessages([]);
      // 2004-05-21 rest
      var dob = patient.dob;
      if (dob !== null) {
        var d = new Date(dob);
        // calculate age in format 25Y or 6M where Y is year and M is month
        var age = Math.floor(
          (new Date() - new Date(dob).getTime()) / 3.15576e10
        );
        var month = Math.floor(
          (new Date() - new Date(dob).getTime()) / 2.62974e9
        );
        if (age > 0) {
          setAge(age + "Y");
        } else {
          setAge(month + "M");
        }
      } else {
        setAge("NA");
      }
      getDepartmentAndTest();
      setDone(true);
    }
  }, []);

  useBeforeunload((event) => {
    appContext.setInCall(false);
    if (saved === false) {
      event.preventDefault();
      var pres = appContext.currentPrescription;
      createCookie("prescription", JSON.stringify(pres), 5);
    }
  });



  return (
    <div id="room">
      <div className="room-header">
        <AdminHeader doctorProfile={doctor} />
      </div>

      <div className="chat-mobile-header">
        <div
          onClick={() => {
            setMobileSection("video");
          }}
          className={mobileSection === "video" ? "active" : ""}
        >
          <p>Call</p>
        </div>
        <div
          onClick={() => {
            setMobileSection("chat");
            section.current = "chat";
            setUnread(false)
          }}
          className={mobileSection === "chat" ? "active" : ""}
        >
           <div style={{
                  display: 'flex',
                  alignItems: 'center',
               }}>
               <p>Chat  </p>
              {
                unread &&  <span style={{
                  color: 'red',
                  position: 'relative',
                  top: -7,
                  left: 10,
                  fontSize: '30px',
                 }}>•</span>
              }
               </div>
        </div>
        <div
          onClick={() => {
            setMobileSection("prescription");
            section.current = "prescription";
          }}
          className={mobileSection === "prescription" ? "active" : ""}
        >
          <p>Prescription</p>
        </div>
      </div>
      <div className="room-cont">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
          className="call-room"
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#0D1423",
            }}
            id="videos"
            ref={videoRef}
          >
          </div>

          {mobileSection === "chat" && (
            <ChatScreen
              roomId={roomId}
              fromMob={true}
              isChat={mobileSection === "chat"}
              height={chatHeight}
              lm_reference_id={lm_reference_id}
            />
          )}
          {mobileSection === "prescription" && (
            <PrescriptionScreen
              patient={patient}
              roomId={roomId}
              fromMob={true}
              departments={departments}
              itemMasters={itemMasters}
              typeMaster={typeMaster}
              isPres={mobileSection === "prescription"}
              height={chatHeight}
              lm_reference_id={lm_reference_id}
              setSaved={setSaved}
              updatePres={updatePrescriptionId}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          className="chat-room"
        >
          <div id="initial-header">
            <div className="prescription-header">
              <div className="pres-patient-det">
                <p
                  className="pres-det-p"
                  style={{
                    fontWeight: "bolder",
                  }}
                >
                  Patient Details
                </p>
                <div style={{}} className="row">
                  <div className="col-5">
                    <p className="det-s">
                      <span

                        style={{
                          fontWeight: 600,
                        }}
                      >
                        Name:
                      </span>{" "}
                      <br />{" "}
                      {patient.name +
                        " - " +
                        age +
                        " / " +
                        (patient.sex
                          ? patient.sex.slice(0, 1).toUpperCase()
                          : "NA")}
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                    className="col-3"
                  >
                    <p>
                      {" "}
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        Ref No:
                      </span>{" "}
                      <br /> {patient.refNo}
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                    className="col-2"
                  >
                    <p>
                      {" "}
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        B. Group:
                      </span>{" "}
                      <br />
                      {patient.bloodGroup}
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      // backgroundColor: "red",
                    }}
                    className="col"
                  >
                    <p>
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        Status:
                      </span>{" "}
                      <br />
                      {patient.maritalStatus}
                    </p>
                  </div>
                </div>
              </div>
              <div className="pres-patient-photo-cont">
                <div className="pres-patient-photo">
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={patient.photo}
                    alt="patient"
                  />
                </div>
              </div>
            </div>
            <div className="chat-header">
              <div
                onClick={() => {
                  setChatSection("chat");
                  section.current = "chat";
                  setUnread(false)
                }}
                className={chatSection === "chat" ? "active" : ""}
              >
                 <div style={{
                  display: 'flex',
                  alignItems: 'center',
               }}>
               <p>Chat  </p>
              {
                unread &&  <span style={{
                  color: 'red',
                  position: 'relative',
                  top: -7,
                  left: 10,
                  fontSize: '30px',
                 }}>•</span>
              }
               </div>
              </div>
              <div
                onClick={() => {
                  setChatSection("prescription");
                  section.current = "prescription";
                }}
                className={chatSection === "prescription" ? "active" : ""}
              >
                <p>Prescription</p>
              </div>
            </div>
          </div>
          {chatSection === "chat" ? (
            <ChatScreen roomId={roomId} height={chatHeight} lm_reference_id={lm_reference_id} />
          ) : (
            <PrescriptionScreen
              patient={patient}
              roomId={roomId}
              height={chatHeight}
              departments={departments}
              itemMasters={itemMasters}
              typeMaster={typeMaster}
              lm_reference_id={lm_reference_id}
              setSaved={setSaved}
              updatePres={updatePrescriptionId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const VideoPlayer = ({ user }) => {
  const ref = useRef();

  useEffect(() => {
    user.videoTrack?.play(ref.current);
  }, []);

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
      }}
    ></div>
  );
};
