
import axios from 'axios';


axios.defaults.withCredentials = true;

const config = {headers: { 
    "Content-Type": "application/json"
}};


const imageConfig = {headers: {'Content-Type': 'multipart/form-data'}};

const urlHead = 'https://server.liveopd.in/api/v2/';



export const isDoctor = async(id) => {
    const url = urlHead + 'isDoctorCheck/'+id;
    try{
        const response = await axios.get(url, config);
        const body = await response.data;
        return body;
    } catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}


export const sendPushNotification = async (map, topic) => {
    const url = urlHead + 'sendPushNotification';
    var map2 = {
        call_queue: map,
        topic: topic.toString()
    };
    console.log(map2);
    try {
      var response = await axios.post(url, map2, config);
      var body = await response.data;
      return body;
    }
    catch (e) {
      return {success: false, message: e.message};
    }
  }


export const incrementNoOfDoctorsOnline = async() => {
    const url = urlHead + 'incrementNoOfDoctorsOnline';
    try{
        const response = await axios.put(url, config);
        const body = await response.data;
        return body;
    } catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}

export const decrementNoOfDoctorsOnline = async() => {
    const url = urlHead + 'decrementNoOfDoctorsOnline';
    try{
        const response = await axios.put(url, config);
        const body = await response.data;
        return body;
    } catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}
