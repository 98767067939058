import { useEffect, useRef, useState } from "react";
import { AiOutlineConsoleSql } from "react-icons/ai";

const SearchBox = (props) => {
  const [datas, setDatas] = useState([]);
  const [bdatas, setBDatas] = useState([]);

  const [open, setOpen] = useState(false);

  const [searchData, setSearchData] = useState("");

  const resultContainer = useRef(null);

  const [focusedIndex, setFocusedIndex] = useState(-1);

  const handleKeyDown = (e) => {
    let nextIndexCount = 0;
    if(e.key === "ArrowDown"){
        nextIndexCount = (focusedIndex + 1) % datas.length;
    }

    if(e.key === 'ArrowUp'){
        nextIndexCount = (focusedIndex + datas.length - 1) % datas.length;
    }

    if(e.key === "Enter"){
      const selected = datas[focusedIndex];
      setSearchData(selected);
      setOpen(false);
      if(selected){
          props.onChange(selected);
      }
      return
    }

    setFocusedIndex(nextIndexCount);
  }

  useEffect(() => {
    setDatas(props.data);
  }, []);

  useEffect(() => {
    setBDatas(props.data);
    document.body.addEventListener('click', ()=>{
      setOpen(false);
    })
  });

  useEffect(() => {
    if(!resultContainer.current) return;

    resultContainer.current.scrollIntoView({
      block: 'center',
    })
  }, [focusedIndex]);

  return (
    <div
    tabIndex={1}
    onKeyDown={handleKeyDown}
      className="search-box"
    >
        <input
        style={{
          position: 'relative',
          bottom: 25
        }}
       autoComplete="nope"
        onKeyDown={(e)=>{
          if(e.key === 'ArrowUp' || e.key === 'ArrowDown'){
            e.preventDefault();
          }
        }}
        value={props.value}
          onChange={(e) => {
            setSearchData(e.target.value);
            if (e.target.value.length > 0) {
             
              var d = bdatas.filter((data) => {
                return data
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase());
              });
              props.onChange(e.target.value);
              setDatas(d);
              setOpen(true);
            } else {
              setOpen(false);
              props.onChange('');
            }
          }}
          type="text"
          id="searchBox"
          placeholder={props.placeholder}
        />

        {open ? (
          <div
            className={props.col}
            style={{
              maxHeight: 200,
              overflow: 'auto',
              position: "absolute",
              width: '100%',
              backgroundColor: "white",
              marginTop: -22,
              marginLeft: 5,
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
              zIndex: 3
            }}
          >
            {datas.map((data, index) => {
              return (
                <div
                  key={index}
                  ref={index === focusedIndex ? resultContainer : null}
                  onClick={() => {
                    setSearchData(data);
                    props.onChange(data);
                    setOpen(false);
                  }}
                  style={{
                    padding: 10,
                    borderBottom: "1px solid #e0e0e0",
                    cursor: "pointer",
                    backgroundColor: index === focusedIndex ? '#ADD8E6' : 'white'
                  }}
                >
                  {data}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
  );
};

export default SearchBox;
