import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyDOSprLQjAV61jXFLJX5ZNTvn65aG0QhhE",
  authDomain: "liveopd-9c04d.firebaseapp.com",
  projectId: "liveopd-9c04d",
  storageBucket: "liveopd-9c04d.appspot.com",
  messagingSenderId: "445846298260",
  appId: "1:445846298260:web:d81ffff214cb92ac8c9e81",
  measurementId: "G-CX9DXDRTJ4"
};

// Use this to initialize the firebase App
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

export { auth, firebase };