import { Component, React, useEffect, useState } from "react";
import { getCookie } from "../js/cookies";
import Login from "../pages/auth/Login";
import { auth } from "../firebaseConfig"; 
import {useAuthState} from "react-firebase-hooks/auth";

const ProtectedRoute = (props) => {
    const [user] = useAuthState(auth);

    useEffect(()=>{
    })
    return (
        user ?  props.children : <Login/>
      );
}

export default ProtectedRoute;