import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminPanel from "../pages/admin/AdminPanel";
import ProtectedRoute from "./ProtectedRoute";
import { CallQueue } from "../pages/admin/panels/CallQueue";
import { VideoCallRoom } from "../pages/conversation/VideoCall";
import { CallHistory } from "../pages/admin/panels/CallHistory";
import { LogInOutRegister } from "../pages/admin/panels/LoginOutRegister";
import { OutgoingVideoCallRoom } from "../pages/conversation/OutgoingVideoCall";


const RouterController = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ProtectedRoute><AdminPanel/></ProtectedRoute>}>
                    <Route path="/callQueue"  element={<CallQueue />} />
                    <Route path="/callHistory"  element={<CallHistory />} />
                    <Route path="/logInOutRegister"  element={<LogInOutRegister />} />
                </Route>
                <Route path="/room/:roomId"  element={<VideoCallRoom />} />
                <Route path="/room_cb/:roomId"  element={<OutgoingVideoCallRoom />} />
            </Routes>
        </BrowserRouter>
    );
}

export default RouterController;