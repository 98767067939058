import { useContext, useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp, IoIosTrash } from "react-icons/io";
import { createPrescription, getPrescriptionByLmId, getPrescriptionByPId, updatePrescription } from "../../js/convo";
import {AiOutlineCloseCircle} from 'react-icons/ai';
import { AppDataContext } from "../../context/AppContext";
import { getCookie } from "../../js/cookies";
import * as React from 'react';
import SearchBox from "./components/SearchBox";


export const PrescriptionScreen = (props) => {
  const [investigations, setInvestigations] = useState([]);
  const [prescription, setPrescription] = useState([]);

  const [testNames, setTestNames] = useState([]);

  const [open , setOpen] = useState(false);

  const [deptSearch, setDeptSearch] = useState('');
  const [testSearch, setTestSearch] = useState('');
  const [medicineSearch, setMedicineSearch] = useState('');
  const [doseSearch, setDoseSearch] = useState('');
  const [remarksSearch, setRemarksSearch] = useState('');

  const [saved, setSaved] = useState(false);
  

  const [medicines , setMedicines] = useState([]);

  const [dose, setDose] = useState([]);

  const [remarks, setRemarks] = useState([]);

  const appDataContext = useContext(AppDataContext);

  const [deptClear, setDeptClear] = useState(false);

  

  const getPrevVisits = async () => {
    var tdatDate = new Date().toLocaleDateString();
    tdatDate = tdatDate.split("/");
    if(tdatDate[0].length === 1){
      tdatDate[0] = "0"+tdatDate[0]
    }
    if(tdatDate[1].length === 1){
      tdatDate[1] = "0"+tdatDate[1]
    }
    tdatDate = tdatDate[0]+"/"+tdatDate[1]+"/"+tdatDate[2];
    appDataContext.setPrevVists([]);
    appDataContext.setPrevPrescriptions([]);
    var t_prevs = [tdatDate];
    var t_press = [appDataContext.currentPrescription];
    appDataContext.setSelectedIndex(0);
    appDataContext.setTodaysIndex(0);
      var res = await getPrescriptionByLmId(props.patient.lm_reference_id);
      if(res.success){
          for(var i =0;i<res.result.length;i++){
            var t_prescription = {};
            var id = res.result[i].bill.createdAt.slice(0,10);
            var day = id.slice(8, 10);
            var mon = id.slice(5, 7);
            var year = id.slice(0, 4);
            id = day+"/"+mon+"/"+year;
            t_prevs.push(id);
            var bill = res.result[i].bill;
            var diagnosis = res.result[i].diagnosis;
            var prescriptionsT = res.result[i].prescriptions;
            var investigations = res.result[i].investigations;
            delete bill.id;
            delete bill.createdAt;
            delete bill.prescription_id;
            bill.prescription_id = appDataContext.currentPrescription.prescription_id;
            delete bill.updatedAt;
            t_prescription = {...t_prescription, ...bill};
            delete diagnosis.id;
            delete diagnosis.createdAt;
            delete diagnosis.updatedAt;
            delete diagnosis.prescription_id;
            t_prescription = {...t_prescription, ...diagnosis};
            var tp = [];
            var ti = [];
            for(var j = 0;j<prescriptionsT.length;j++){
              var t = prescriptionsT[j];
              delete t.createdAt;
              delete t.updatedAt;
              delete t.prescription_id;
              tp.push(t);
            }
            for(var j2 = 0;j2<investigations.length;j2++){
              var j1 = investigations[j2];
              delete j1.createdAt;
              delete j1.updatedAt;
              delete j1.prescription_id;
              ti.push(j1);
            }
            t_prescription.prescriptions = tp;
            t_prescription.investigations = ti;
            t_press.push(t_prescription);
          }
          appDataContext.setPrevVists(t_prevs);
          appDataContext.setPrevPrescriptions(t_press);
      }
     
  }

  useEffect(()=>{
    var doct = getCookie("doctor");
    if(doct){
      doct = JSON.parse(doct);
    }
   

    getPrevVisits();

    var i = props.itemMasters;
    var type = props.typeMaster;
    var tests = [];
    var dept = document.getElementById('department').value;
    for(var j = 0; j < i.length; j++){
      if(i[j].DEPARTMENT === dept){
        tests.push(i[j].ITEM_NAME);
      }
    }
    setTestNames(tests);

    var med = [];
    for(var j3 = 0; j3 < i.length; j3++){
      if(i[j3].DEPARTMENT === "Medicine"){
        med.push(i[j3].ITEM_NAME);
      }
    }
    console.log(med)
    setMedicines(med);

    var d = [];
    for(var j1 = 0; j1 < type.length; j1++){
      if(type[j1].MASTER_NAME === "Dose"){
        d.push(type[j1].TYPE_NAME);
      }
    }
    setDose(d);

    var r = [];
    for(var j2 = 0; j2 < type.length; j2++){
      if(type[j2].MASTER_NAME === "Dosage Interval"){
          r.push(type[j2].TYPE_NAME);
    }
    setRemarks(r);
  }

  }, [])

  return (
   <AppDataContext.Consumer>
    {
      appData =>  <div
      style={{
        display: props.fromMob ? (props.isPres ? "flex" : "none") : "flex",
        overflowX: 'hidden'
      }}
      id="chatCont"
      className={props.fromMob ? "chat-mobile-cont" : "chat-cont"}
    >
      <div style={{
        width: "100%",
        borderBottom: '1px solid #212121',
      }} className="previous-visits">
       
        <div className="row">
         
          {
            props.closeBtn && <div style={{
              textAlign: "right",
            }} className="col">
              < AiOutlineCloseCircle onClick={()=>{
                props.closeBtnFunction();
              }} style={{
                fontSize: 25,
                color: 'red',
                cursor: 'pointer'
              }}/>
            </div>
          }
        </div>
        {
          !props.closeBtn && <>
          <div style={{
            position: "relative",
            overflow: "hidden",
          }} className="scrollmenu">
          {
            appData.prevVisits.length > 0 ?  appData.prevVisits.map((visit, index) => {
              return <span onClick={()=>{
                appData.setSelectedIndex(index);
               appData.setCurrentPrescription(appData.prevPrescriptions[index]);
              }} style={{
               color: appData.selectedIndex === index && '#040b18',
               fontSize: appData.selectedIndex === index && 17
              }}>{visit}</span>
            }) : <p style={{
              position: 'relative',
              top: 15,
              fontWeight: 600
            }}>No Previous Prescriptions Found</p>
          }
         </div>
        
         </>
        }
        
      </div>
      
      
      <div className="row">
              <div className="col">
                <p
                  style={{
                    padding: 5,
                    fontWeight: 600,
                  }}
                >
                  Prescription Id: {appData.currentPrescription.prescription_id}
                </p>
              </div>
              <div className="col">
                <p
                  style={{
                    padding: 5,
                    fontWeight: 600,
                  }}
                >
                  Date: {new Date().toLocaleDateString()}
                </p>
              </div>
            </div>
      <div className="prescriptions">
        <div className="pres-accordian">
          <div
            onClick={() => {
              var icon = document.getElementsByClassName("accordian-icon")[0];
              var bottom = document.getElementsByClassName(
                "pres-accordian-bottom"
              )[0];
              if (icon.classList.contains("down")) {
                icon.classList.remove("down");
              } else {
                icon.classList.add("down");
              }
              if (bottom.classList.contains("show")) {
                bottom.classList.remove("show");
              } else {
                bottom.classList.add("show");
              }
            }}
            className="pres-accordian-top"
          >
            <p>Diagnosis</p>
            <IoIosArrowDown
              className="accordian-icon down"
              style={{
                fontSize: 20,
                marginRight: 5,
              }}
            />
          </div>
          <div className="pres-accordian-bottom show">
            <br />
            <div className="row">
              <div className="col a">
                <div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Chief Complaint/ Examination
                    </p>

                    <label style={{
                      display: "flex",
                    }}>
                      <p style={{
                        marginRight: 5,
                        fontWeight: 600,
                        color: '#2d56a9'
                      }}>Print</p>
                    <input disabled={appData.selectedIndex !== 0} onChange={(e)=>{
                      appData.changePrescription("print_chief", e.target.checked);
                      var m = appData.prevPrescriptions;
                    m[appData.selectedIndex] = appData.currentPrescription;
                      appData.setPrevPrescriptions(m);
                    }} checked={appData.currentPrescription.print_chief} className="printCheck" type="checkbox" name="printCheifComplain" id="printCheifComplain" />
                    </label>
                  </div>
                  <textarea
                  readOnly={appData.selectedIndex !== 0}
                    name="complain"
                    onChange={(e) => {
                      appData.changePrescription("chief_complain", e.target.value);
                      var m = appData.prevPrescriptions;
                    if(e.target.value !== ""){
                      appData.changePrescription("print_chief", true);
                    }
                    else{
                      appData.changePrescription("print_chief", false);
                    }
                    m[appData.selectedIndex] = appData.currentPrescription;
                      appData.setPrevPrescriptions(m);
                    }}
                    value={appData.currentPrescription.chief_complain || ""}
                    id="complain"
                    cols="45"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div className="col a">
                <div>
                <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      History
                    </p>

                    <label style={{
                      display: "flex",
                    }}>
                      <p style={{
                        marginRight: 5,
                        fontWeight: 600,
                        color: '#2d56a9'
                      }}>Print</p>
                    <input disabled={appData.selectedIndex !== 0} onChange={(e)=>{
                      appData.changePrescription("print_history", e.target.checked);
                      var m = appData.prevPrescriptions;
                    m[appData.selectedIndex] = appData.currentPrescription;
                      appData.setPrevPrescriptions(m);
                    }} checked={appData.currentPrescription.print_history} className="printCheck" type="checkbox" name="printHistory" id="printHistory" />
                    </label>
                  </div>
                  <textarea
                  readOnly={appData.selectedIndex !== 0}
                    name="history"
                    onChange={(e) => {
                      appData.changePrescription("history", e.target.value);
                      if(e.target.value !== ""){
                        appData.changePrescription("print_history", true);
                      }
                      else{
                        appData.changePrescription("print_history", false);
                      }
                      var m = appData.prevPrescriptions;
                      m[appData.selectedIndex] = appData.currentPrescription;
                        appData.setPrevPrescriptions(m);
                    }}
                    value={appData.currentPrescription.history || ""}
                    id="history"
                    cols="45"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col a">
                <div>
                <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Provinsional Diagnosis
                    </p>

                    <label style={{
                      display: "flex",
                    }}>
                      <p style={{
                        marginRight: 5,
                        fontWeight: 600,
                        color: '#2d56a9'
                      }}>Print</p>
                    <input disabled={appData.selectedIndex !== 0} onChange={(e)=>{
                      appData.changePrescription("print_examination", e.target.checked);
                      var m = appData.prevPrescriptions;
                    m[appData.selectedIndex] = appData.currentPrescription;
                      appData.setPrevPrescriptions(m);
                    }} checked={appData.currentPrescription.print_examination} className="printCheck" type="checkbox" name="printExamprintHistory" id="printExamprintHistory" />
                    </label>
                  </div>
                  <textarea
                  readOnly={appData.selectedIndex !== 0}
                    name="examination"
                    onChange={(e) => {
                      appData.changePrescription("examination", e.target.value);
                      if(e.target.value !== ""){
                        appData.changePrescription("print_examination", true);
                      }
                      else{
                        appData.changePrescription("print_examination", false);
                      }
                      var m = appData.prevPrescriptions;
                    m[appData.selectedIndex] = appData.currentPrescription;
                      appData.setPrevPrescriptions(m);
                    }}
                    value={appData.currentPrescription.examination || ""}
                    id="examination"
                    cols="45"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div className="col a">
                <div>
                <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Advice
                    </p>

                    <label style={{
                      display: "flex",
                    }}>
                      <p style={{
                        marginRight: 5,
                        fontWeight: 600,
                        color: '#2d56a9'
                      }}>Print</p>
                    <input disabled={appData.selectedIndex !== 0} onChange={(e)=>{
                      appData.changePrescription("print_advice", e.target.checked);
                      var m = appData.prevPrescriptions;
                    m[appData.selectedIndex] = appData.currentPrescription;
                      appData.setPrevPrescriptions(m);
                    }} checked={appData.currentPrescription.print_advice} className="printCheck" type="checkbox" name="printAdvice" id="printAdvice" />
                    </label>
                  </div>
                  <textarea
                  readOnly={appData.selectedIndex !== 0}
                    name="advice"
                     onChange={(e) => {
                      appData.changePrescription("advice", e.target.value);
                      if(e.target.value !== ""){
                        appData.changePrescription("print_advice", true);
                      }
                      else{
                        appData.changePrescription("print_advice", false);
                      }
                      var m = appData.prevPrescriptions;
                    m[appData.selectedIndex] = appData.currentPrescription;
                      appData.setPrevPrescriptions(m);
                    }}
                    value={appData.currentPrescription.advice || ""}
                    id="advice"
                    cols="45"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pres-accordian">
          <div
            onClick={() => {
              var icon = document.getElementsByClassName("accordian-icon")[1];
              var bottom = document.getElementsByClassName(
                "pres-accordian-bottom"
              )[1];
              if (icon.classList.contains("down")) {
                icon.classList.remove("down");
              } else {
                icon.classList.add("down");
              }
              if (bottom.classList.contains("show")) {
                bottom.classList.remove("show");
              } else {
                bottom.classList.add("show");
              }
            }}
            className="pres-accordian-top"
          >
            <p>Investigation</p>
            <IoIosArrowDown
              className="accordian-icon down"
              style={{
                fontSize: 20,
                marginRight: 5,
              }}
            />
          </div>
          <div className="pres-accordian-bottom show">
           

            <div style={{
              display:appData.selectedIndex !== 0 ? 'none' : 'flex',
            }} className="row">
              <div className="col-4 inves">
              <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                    }}
                  >
                    Department
                  </p>
                  {/* <select style={{
                    position: 'relative',
                    bottom: 25
                  }} onChange={(e)=>{
                      var i = props.itemMasters;
                      var tests = [];
                      for(var j = 0; j < i.length; j++){
                        if(i[j].DEPARTMENT === e.target.value){
                          tests.push(i[j].ITEM_NAME);
                        }
                      }
                      setTestNames(tests);
                  }} name="department" id="department">
                    {
                      props.departments.map((department)=>{
                       if(department !== "Medicine"){
                        return <option value={department}>{department}</option>
                       }
                      })
                    }
                  </select> */}
                   <input style={{display:'none'}} type="text" id="department" />
                  <SearchBox col={'col-3'} value={deptSearch} data={props.departments} onChange={(e)=>{
                    setDeptSearch(e);
                    document.getElementById('department').value = e;
                    var i = props.itemMasters;
                    var tests = [];
                    for(var j = 0; j < i.length; j++){
                      if(i[j].DEPARTMENT === e){
                        tests.push(i[j].ITEM_NAME);
                      }
                    }
                    setTestNames(tests);
                  }} placeholder="Enter Department"/>
                </div>
              </div>
              <div className="col-6 inves">
              <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                    }}
                  >
                    Test Name
                  </p>
                  {/* <select style={{
                    position: 'relative',
                    bottom: 25
                  }} name="testName" id="testName">
                    {
                      testNames.map((testName)=>{
                        return <option value={testName}>{testName}</option>
                      })}
                  </select> */}
                    <input style={{display:'none'}} type="text" id="testName" />
                  <SearchBox col={'col-3'} value={testSearch} data={testNames} onChange={(e)=>{
                    setTestSearch(e);
                    document.getElementById('testName').value = e;
                  }} placeholder="Enter Test Name"/>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: 'relative',
                  bottom: 10
                }}
                className="col-2 inves"
              >
                <input
                onClick={()=>{
                  if(deptSearch.trim() !== '' && testSearch.trim() !== ''){
                   if(props.departments.includes(deptSearch) && testNames.includes(testSearch)){
                    var len = appData.currentPrescription.investigations ? appData.currentPrescription.investigations.length : 0;
                  var map = {
                    id: len + 1,
                    department: deptSearch, 
                    test_name: testSearch
                  }
                  var got = false;
                  for(var i=0;i<appData.currentPrescription.investigations.length;i++){
                    if(!got){
                      if(appData.currentPrescription.investigations[i].department === deptSearch && appData.currentPrescription.investigations[i].test_name === testSearch){
                        got = true;
                        var prompt = window.confirm("Investigation already added. Do you want to add again?");
                        if(!prompt){
                          return;
                        }
                      }
                    }
                  }
                  setInvestigations([...investigations, map])
                  appData.changePrescription("investigations", [...investigations, map]);
                  var m = appData.prevPrescriptions;
                    m[appData.selectedIndex] = appData.currentPrescription;
                      appData.setPrevPrescriptions(m);
                      setDeptSearch('');
                      setTestSearch('');
                   }
                }
                }}
                  style={{
                    width: "80%",
                    backgroundColor: "#163470",
                    position: "relative",
                    top: 7,
                    color: "#fff",
                    outline: 'none',
                    border: 'none',
                    borderRadius: 5,
                    padding: 5,
                  }}
                  type="button"
                  value="Add"
                />
              </div>
            </div>

            
           <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: appData.selectedIndex !== 0 ? 10 : 0
           }}>
           <table id="pres-acc-table">
              <thead>
                <tr>
                  <th>Department</th>
                  <th>Test Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  appData.currentPrescription.investigations && appData.currentPrescription.investigations.map((item, i)=>{
                    return <tr>
                    <td>{item.department}</td>
                    <td>{item.test_name}</td>
                    <td>
                      <IoIosTrash
                        onClick={()=>{
                          var l = appData.currentPrescription.investigations;
                          
                          l = l.filter((map)=> map.id !== item.id);
                          
                          setInvestigations(l);
                          appData.changePrescription("investigations", l);
                          var m = appData.prevPrescriptions;
                    m[appData.selectedIndex] = appData.currentPrescription;
                      appData.setPrevPrescriptions(m);
                        }}
                        style={{
                          fontSize: 25,
                          cursor: "pointer",
                          color: "red",
                        }}
                      />
                    </td>
                  </tr>
                  })
                }
                </tbody>
            </table>
           </div>
          </div>
        </div>

        <div className="pres-accordian">
          <div
            onClick={() => {
              var icon = document.getElementsByClassName("accordian-icon")[2];
              var bottom = document.getElementsByClassName(
                "pres-accordian-bottom"
              )[2];
              if (icon.classList.contains("down")) {
                icon.classList.remove("down");
              } else {
                icon.classList.add("down");
              }
              if (bottom.classList.contains("show")) {
                bottom.classList.remove("show");
              } else {
                bottom.classList.add("show");
              }
            }}
            className="pres-accordian-top"
          >
            <p>Prescription</p>
            <IoIosArrowDown
              className="accordian-icon down"
              style={{
                fontSize: 20,
                marginRight: 5,
              }}
            />
          </div>
          <div className="pres-accordian-bottom show">

            <div style={{
              display:appData.selectedIndex !== 0 ? 'none' : 'flex',

            }} className="s">
              <div className="col-4 inves">
              <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                    }}
                  >
                    Medicine
                  </p>
                  {/* <select style={{
                    position: 'relative',
                    bottom: 25
                  }} name="medicine" id="medicine">
                    {
                      medicines.map((medicine)=>{
                        return <option value={medicine}>{medicine}</option>
                      }
                      )
                    }
                  </select> */}
                   <input style={{display:'none'}} type="text" id="medicine" />
                  <SearchBox col={'col-3'} value={medicineSearch} data={medicines} onChange={(e)=>{
                    setMedicineSearch(e);
                    document.getElementById('medicine').value = e;
                  }} placeholder="Enter Medicine"/>
                </div>
              </div>
              <div className="col inves">
              <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                     
                    }}
                  >
                   Dose
                  </p>
                  {/* <select style={{
                    position: 'relative',
                    bottom: 25
                  }} name="dose" id="dose">
                    {
                      dose.map((dose)=>{
                        return <option value={dose}>{dose}</option>
                      })
                    }
                  </select> */}
                   <input style={{display:'none'}} type="text" id="dose" />
                  <SearchBox col={'col-3'} value={doseSearch} data={dose} onChange={(e)=>{
                    setDoseSearch(e);
                    document.getElementById('dose').value = e;
                    var dose = e;
                    if(dose !== ""){
                      dose = dose.split("-");
                      var count = 0;
                      for(var i=0;i<dose.length;i++){
                        if(dose[i].trim() === '1'){
                          count++;
                        } else if(dose[i].trim() === '1/2'){
                          count += 0.5;
                        }
                      }
                      var final = count * parseInt(document.getElementById('days').value);
                      document.getElementById('quantity').value = Math.ceil(final);
                    }
                    else{
                      document.getElementById('quantity').value = "0";
                    }
                  }} placeholder="Enter Dose"/>
                </div>
              </div>
              <div className="col inves">
              <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                      width: 50,
                    }}
                  >
                   Days
                  </p>
                  <input style={{
                    position: 'relative',
                    bottom: 25
                  }} onChange={()=>{
                    var dose = document.getElementById('dose').value;
                    if(dose !== ""){
                      dose = dose.split("-");
                      var count = 0;
                      for(var i=0;i<dose.length;i++){
                        if(dose[i].trim() === '1'){
                          count++;
                        } else if(dose[i].trim() === '1/2'){
                          count += 0.5;
                        }
                      }
                      var final = count * parseInt(document.getElementById('days').value);
                      document.getElementById('quantity').value = Math.ceil(final);
                    }
                    else{
                      document.getElementById('quantity').value = "0";
                    }
                  }} defaultValue={1} placeholder="Days" type="number" name="days" id="days" />
                </div>
              </div>
              <div className="col inves">
              <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                      width: 50,
                    }}
                  >
                   Quantity
                  </p>
                  <input style={{
                    position: 'relative',
                    bottom: 25
                  }}  defaultValue={1} placeholder="Quantity" readOnly={true} type="number" name="quantity" id="quantity" />
                </div>
              </div>
              <div className="col-5  inves">
              <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                    }}
                  >
                   Dosage Interval
                  </p>
                  {/* <select style={{
                    position: 'relative',
                    bottom: 25
                  }} name="remarks" id="remarks">
                    {
                      remarks.map((remark)=>{
                        return <option value={remark}>{remark}</option>
                      }
                      )
                    }
                  </select> */}
                   <input style={{display:'none'}} type="text" id="remarks" />
                  <SearchBox col={'col-3'} value={remarksSearch} data={remarks} onChange={(e)=>{
                    setRemarksSearch(e);
                    document.getElementById('remarks').value = e;
                  }} placeholder="Enter Dosage Interval"/>
                </div>
              </div>
              
            </div>
            <div
                style={{
                  display:appData.selectedIndex !== 0 ? 'none' : 'flex',
                  alignItems: "center",
                }}
                className="col inves"
              >
                <input
                onClick={()=>{
                  var days = document.getElementById('days').value;
                  var quantity = document.getElementById('quantity').value;
                  if(medicineSearch.trim() !== '' && doseSearch.trim() !== ''){
                  var len2 = appData.currentPrescription.prescriptions ? appData.currentPrescription.prescriptions.length : 0;
                  var map = {
                    id: len2 + 1,
                    medicine: medicineSearch,
                    dose: doseSearch,
                    days: parseInt(days),
                    quantity: parseInt(quantity),
                    remarks: remarksSearch
                  }
                  var got = false;
                  if(len2 > 0){
                    for(var i=0;i<appData.currentPrescription.prescriptions.length;i++){
                      if(!got){
                        if(appData.currentPrescription.prescriptions[i].medicine === map.medicine && appData.currentPrescription.prescriptions[i].dose === map.dose && appData.currentPrescription.prescriptions[i].remarks === map.remarks){
                          got = true;
                          var prompt = window.confirm("Prescription already added. Do you want to add again?");
                          if(!prompt){
                            return;
                          }
                        }
                      }
                    }
                  }
                  setPrescription([...prescription, map]);
                  appData.changePrescription("prescriptions", [...prescription, map]);
                  var m = appData.prevPrescriptions;
                    m[appData.selectedIndex] = appData.currentPrescription;
                      appData.setPrevPrescriptions(m);

                      setMedicineSearch('');
                      setDoseSearch('');
                      setRemarksSearch('');
                      document.getElementById('days').value = 1;
                      document.getElementById('quantity').value = 1;
                }
                }}
                  style={{
                    width: 150,
                    backgroundColor: "#163470",
                    position: "relative",
                    color: "#fff",
                    outline: 'none',
                    border: 'none',
                    borderRadius: 5,
                    padding: 5,
                  bottom: 25
                  }}
                  type="button"
                  value="Add"
                />
              </div>
            <div style={{
            display: 'flex',
            justifyContent: 'center',
           }}>
           <table id="pres-acc-table">
              <thead>
                <tr>
                  <th>Medicine</th>
                  <th>Dose</th>
                  <th>Days</th>
                  <th>Quantity</th>
                  <th>Remarks</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                 appData.currentPrescription.prescriptions && appData.currentPrescription.prescriptions.map((item, i)=>{
                    return <tr>
                    <td>{item.medicine}</td>
                    <td>{item.dose}</td>
                    <td>{item.days}</td>
                    <td>{item.quantity}</td>
                    <td>{item.remarks}</td>
                    <td>
                      <IoIosTrash
                        onClick={()=>{
                          var l = appData.currentPrescription.prescriptions;
                          l = l.filter((map)=> map.id !== item.id);
                          setPrescription(l);
                          appData.changePrescription("prescriptions", l);
                          var m = appData.prevPrescriptions;
                          m[appData.selectedIndex] = appData.currentPrescription;
                            appData.setPrevPrescriptions(m);
                        }}
                        style={{
                          fontSize: 25,
                          cursor: "pointer",
                          color: "red",
                        }}
                      />
                    </td>
                  </tr>
                  })
                }
                </tbody>
            </table>
           </div>
          </div>
          
          <br />

          
          <label style={{
            padding: 10,
            display: 'flex',
          }}>
            <p style={{
              fontWeight: 600,
              paddingRight: 10,
              position:'relative',
              top: 10
            }}>Next Visit</p>
          <input readOnly={appData.selectedIndex !== 0} type="number" defaultValue={appData.currentPrescription.next_consultation_date} onChange={(e)=>{
            if(e.target.value < 0){
              e.preventDefault();
            }else{
              appData.changePrescription("next_consultation_date", parseInt(e.target.value));
              var m = appData.prevPrescriptions;
                    m[appData.selectedIndex] = appData.currentPrescription;
                      appData.setPrevPrescriptions(m);
            }
          }} style={{
            width: 100
          }} />

<p style={{
              fontWeight: 600,
              paddingLeft: 10,
              position:'relative',
              top: 10
            }}>Days</p>

          </label>

          <br />

          <div style={{
            display: 'flex',
            padding: 5,
            width: '100%',
          }}>
          
          {
            appData.selectedIndex === 0 && <button onClick={async(e)=>{
              e.preventDefault();
              var temp = appData.currentPrescription;
              for(var i = 0; i < temp.prescriptions.length; i++){
                delete temp.prescriptions[i].id;
              }
              for(var x = 0; x < temp.investigations.length; x++){
                delete temp.investigations[x].id;
              }
              
              var res = !saved ? await createPrescription(temp) : await updatePrescription(appData.currentPrescription.prescription_id, temp);
              if(res.success){
                props.updatePres(appData.currentPrescription.prescription_id);
                props.setSaved(true);
                setSaved(true);
                alert("Prescription saved successfully");
              }
              else{
                alert("Error saving prescription")
              }
            }} style={{
              backgroundColor: '#163470',
              color: '#fff',
              padding: 15,
              borderRadius: 5,
              outline: 'none',
              border: 'none',
              marginRight: 10,
            }}>Save</button>
          }

          </div>

        </div>
      </div>
    </div>
    }
   </AppDataContext.Consumer>
  );
};
