import React, { useContext, useEffect } from "react";
import { BsFillBellFill } from "react-icons/bs";
import {
  createLogRegister,
  getLogInRecords,
  updateLogInRecords,
} from "../../../../js/convo";
import { createCookie, deleteCookie, getCookie } from "../../../../js/cookies";
import { BiLogOut } from "react-icons/bi";
import { auth } from "../../../../firebaseConfig";
import { alpha, styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import { AppDataContext } from "../../../../context/AppContext";
import { SocketContext } from "../../../../context/socket";
import { useBeforeunload } from "react-beforeunload";
import { MdAod } from "react-icons/md";
import { HiMenuAlt1 } from "react-icons/hi";
import { decrementNoOfDoctorsOnline, incrementNoOfDoctorsOnline } from "../../../../js/auth";
import { useState } from "react";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[600],
    "&:hover": {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: green[600],
  },
}));

const AdminHeader = (props) => {
  const socket = useContext(SocketContext);

  const logOutDoctor = async () => {
    updateLogout("second");
    auth.signOut();
    // deleteCookie("doctor");
    // window.location.reload();
  };

  const appDataContext = useContext(AppDataContext);

  const updateLogout = async (when) => {
    var doct = getCookie("doctor");
    if (doct !== "") {
      doct = JSON.parse(doct);
    }
    var res2 = await getLogInRecords(doct.LM_REFERENCE_ID);
    var lgi = getCookie("login");
    var logout_time = "";
    if (when === "first") {
      var lgo = getCookie("logout");
     if(lgo !== ''){
      lgo = JSON.parse(lgo);
      logout_time = lgo.full_dateTime;
      if(JSON.parse(lgi).id !== lgo.id){
        return;
      }
     }
    } else {
      var d = new Date();
      var date = d.toLocaleDateString();
      date = date.split("/");
      date = date[2] + "-" + date[0] + "-" + date[1];
      var hours = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      logout_time = date + " " + hours + ":" + min + ":" + sec;
    }
    var map = {
      logout_time: logout_time,
    };
   
    if (lgi !== "") {
      console.log("==================>")
      console.log(lgi)
      lgi = JSON.parse(lgi);
      var lgid = lgi.id;
      if (res2.data.length > 0) {
        var res3 = await updateLogInRecords(lgid, map);
        if(res3.success){
          decrementNoOfDoctorsOnline();
        }
        if (res3.success) {
          deleteCookie("login");
          deleteCookie("logout");
        }
      }
    }
  };



  useEffect(() => {
    updateLogout("first");
  }, []);
  
  useBeforeunload((event) => {
    var d = new Date();
    var date = d.toLocaleDateString();
    var hours = d.getHours();
    var min = d.getMinutes();
    var sec = d.getSeconds();
    var full_dateTime = date + " " + hours + ":" + min + ":" + sec;
    var lg = getCookie("login");
    lg = JSON.parse(lg);
    var map = {
      full_dateTime: full_dateTime,
      id: lg.id,
    }
    createCookie("logout", JSON.stringify(map), 5);
  });

  return (
    <AppDataContext.Consumer>
      {(appData) => (
        <div className="header-bar">
          <audio
            loop
            src={process.env.PUBLIC_URL + "/assets/ring.mp3"}
            id="Audio"
          >
            {" "}
          </audio>
          <div
            style={{
              height: "100%",
              display: "flex",
            }}
          >
            <div className="header-left">
            <HiMenuAlt1
      style={{
        fontSize:"30px",
        cursor:"pointer",
        position: 'relative',
        color:'#212121',
        marginLeft: 5,
        top: 15
      }}
        onClick={() => {
          // handleSideBar();
          document.getElementById("side_bar").style.width = "280px";
          document.getElementsByClassName("panel_bar")[0].style.width = "calc(100% - 280px)";
        }}
        className="p-head-icon"
      />
            </div>
            <div className="header-right">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  height: "100%",
                }}
              >

                <div className="profile-photo">
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                    }}
                    src={props.doctorProfile.LM_PHOTO}
                    alt=""
                  />
                </div>

                <div
                  style={{
                    height: "100%",
                    textAlign: "left",
                    marginRight: 30,
                    marginLeft: 10,
                    lineHeight: 0.2,
                  }}
                  className="profile-det"
                >
                  <h6
                    style={{
                      marginTop: 12,
                      color: "#212121",
                      fontWeight: 600,
                    }}
                  >
                    Dr. {props.doctorProfile.LM_NAME}
                  </h6>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="active-cont"
                  >
                    <p
                      style={{
                        color: appData.isOnline ? "green" : "red",
                        position: "relative",
                        top: 3,
                        fontWeight: 400,
                      }}
                    >
                      {appData.isOnline ? "Online" : "Offline"}
                    </p>

                    <div
                      style={{
                        position: "relative",
                        bottom: 15,
                      }}
                    >
                      <GreenSwitch
                      disabled={appData.inCall}
                        onChange={(e) => {
                          appData.setIsOnline(e.target.checked);
                          if (e.target.checked) {
                            socket.emit("onHardCodeJoinRoom", {});
                            var doctor = getCookie("doctor");
                            if (doctor !== "") {
                              doctor = JSON.parse(doctor);
                            }
                            var d = new Date();
                            var date = d.toLocaleDateString();
                            date = date.split("/");
                            date = date[2] + "-" + date[0] + "-" + date[1];
                            var hours = d.getHours();
                            var min = d.getMinutes();
                            var sec = d.getSeconds();
                            var full_dateTime =
                              date + " " + hours + ":" + min + ":" + sec;
                            var map = {
                              id: Math.floor(Math.random() * 100000000),
                              lm_reference_id: doctor.LM_REFERENCE_ID,
                              login_time: full_dateTime,
                            };
                            createLogRegister(map);
                            incrementNoOfDoctorsOnline();
                            createCookie("login", JSON.stringify(map), 5);
                          } else {
                            socket.emit("onHardCodedLeaveRoom", {});
                            updateLogout("second");
                          }
                        }}
                        defaultChecked={appData.isOnline}
                      />
                    </div>
                  </div>
                </div>

                <BiLogOut
                  onClick={() => {
                    logOutDoctor();
                  }}
                  style={{
                    fontSize: 24,
                    marginRight: 25,
                    color: "#212121",
                    cursor: "pointer",
                    position: "relative",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </AppDataContext.Consumer>
  );
};

export default AdminHeader;
