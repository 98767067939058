import axios from 'axios';


axios.defaults.withCredentials = true;

const config = {headers: { 
    "Content-Type": "application/json"
}};


const imageConfig = {headers: {'Content-Type': 'multipart/form-data'}};

const urlHead = 'https://server.liveopd.in/api/v2/';

//  Video Call APIs

export const getRoomToken = async (roomName, lm_reference_id) => {
    const url = urlHead + 'joinRoom/';
    var map = {
        roomName: roomName,
        LM_REFERENCE_ID: lm_reference_id
    }
    try{
        const response = await axios.post(url, map,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}


export const saveMessageDB = async (map) => {
    const url = urlHead + 'createMessage';
    try{
        const response = await axios.post(url, map,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}

export const saveAttachmentDB = async (map) => {
    const url = urlHead + 'createAttachment';
    try{
        var formData = new FormData();
        for(const key in map){
            formData.append(key, map[key])
        }
        const response = await axios.post(url, formData,imageConfig);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}


export const getPrescriptionByLmId = async (id) => {
    const url = urlHead + 'getPrescriptionByLmId/'+id;
    try{
        const response = await axios.get(url,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}

export const getPrescriptionByPId = async (id) => {
    const url = urlHead + 'getPrescriptionById/'+id;
    try{
        const response = await axios.get(url,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}

export const createCallLog = async (map) => {
    const url = urlHead + 'createCallLog';
    try{
        const response = await axios.post(url, map,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}

export const getCallHistoryByLmId = async (id) => {
    const url = urlHead + 'getCallLogById/'+id;
    try{
        const response = await axios.get(url,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}


export const getAllCallQueue = async () => {
    const url = urlHead + 'getCallQueue';
    try{
        const response = await axios.get(url,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}


export const deleteCallQueue = async (id) => {
    const url = urlHead + 'deleteCallQueue/'+id;
    try{
        
        const response = await axios.delete(url,config);
        const body = await response.data;
        
        return body;
    }
    catch(e){
        
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}

export const getItemsId = async () => {
    const url = urlHead + "getItemMasterData";
    try {
      const response = await axios.get(url, config);
      const body = await response.data;
      return body;
    } catch (e) {
      var error = await e.response.data.message;
      return { success: false, message: error };
    }
  };

  export const getTypeId = async () => {
    const url = urlHead + "getTypeMasterData";
    try {
      const response = await axios.get(url, config);
      const body = await response.data;
      return body;
    } catch (e) {
      var error = await e.response.data.message;
      return { success: false, message: error };
    }
  };


  export const createPrescription = async (map) => {
    const url = urlHead + 'createPrescription';
    try{
        const response = await axios.post(url, map,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
  }


  export const updatePrescription = async (id, map) => {
    const url = urlHead + 'updatePrescriptionById/'+id;
    try{
        const response = await axios.put(url, map,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
  }



  export const getLogInRecords = async (id) => {
    const url = urlHead + 'findDoctorLoginSessionDataByDoctorId/'+id;
    try{
        const response = await axios.get(url,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
  }


  export const createLogRegister = async (map) => {
    const url = urlHead + 'createDoctorLoginSessionData';
    try{
        const response = await axios.post(url, map, config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
  }

  
  export const updateLogInRecords = async (id, map) => {
    const url = urlHead + 'updateDoctorLoginSessionData/'+id;
    try{
        const response = await axios.put(url, map, config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
  }

  export const updateCallLog = async (id, map) => {
    const url = urlHead + 'updateCallLog/'+id;
    try{
        const response = await axios.put(url, map, config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
  }


  export const getAgoraToken = async(room, uid) => {
    const url = urlHead + `getAccessToken?channelName=${room}&uid=${uid}`;
    try{
        const response = await axios.get(url,config);
        console.log(response)
        const body = await response.data;
        console.log(body)
        return body;
    }
    catch(e){
        console.log(e);
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
  }

  export const getLedgerMasterById = async(id) => {
    const url = urlHead + 'getLedgerMasterDataById/'+id;
    try{
        const response = await axios.get(url,config);
        console.log(response)
        const body = await response.data;
        console.log(body)
        return body;
    }
    catch(e){
        console.log(e);
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
  }

  export const getAgoraDet = async (appid, channelName) => {
    const url = `https://api.agora.io/dev/v1/channel/user/${appid}/${channelName}`;
    try{
        var c_key = 'c4fc575003dc480598f6606fc1b14f27';
        var c_secret = '6aacf23dd3b24807864c9b5ff862b902';
        var cred = c_key + ":" + c_secret;
        var base64Credentials =  window.btoa(cred);
        var authorizationHeader = "Basic " + base64Credentials;
        var t_config = config;
        t_config.headers.Authorization = authorizationHeader;
        const response = await axios.get(url,config);
        console.log(response)
        const body = await response.data;
        console.log(body)
        return body;
    }
    catch(e){
        return {success: false};
    }
  }