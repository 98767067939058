import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLogInRecords } from "../../../js/convo";
import { getCookie } from "../../../js/cookies";
import { MdEdit } from "react-icons/md";
import { AppDataContext } from "../../../context/AppContext";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export const LogInOutRegister = () => {
  const nav = useNavigate();

  const [records, setRecords] = useState([]);

  const [startDate, setStartDate] = useState("");

  const [totalDuration, setTotalDuration] = useState(0);

  const [endDate, setEndDate] = useState("");

  const [backupRecords, setBackupRecords] = useState([]);

  const filterUsingDate = (start, endD) => {
    console.log(backupRecords);
    console.log(start + " " + endD);
    var s_start = start;
    var s_end = endD;
    var sd = new Date(s_start).getTime();
    var ed = new Date(s_end);
    // add 1 more day to ed
    ed.setDate(ed.getDate() + 1);
    ed = ed.getTime();
    var t = backupRecords;
    t = t.filter((d) => {
      var time = new Date(d.login_time).getTime();

      return sd < time && time < ed;
    });
    var tD = 0;
    for (var x = 0; x < t.length; x++) {
      tD = tD + t[x].duration;
    }
    var hours = Math.floor(tD / 60);
    var minutes = tD % 60;

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    var dur = hours + ":" + minutes;
    setTotalDuration(dur);
    setRecords(t);
  };

  const getRecords = async (doctor_id) => {
    var res = await getLogInRecords(doctor_id);
    if (res.success) {
      var s = res.data;
      var tD = 0;
      s = s.reverse();
      s = s.filter((item) => {
        return item.logout_time !== null;
      });

      var d1 = await getDate();
      var s_start = d1;
      var s_end = d1;
      var sd = new Date(s_start).getTime();
      var ed = new Date(s_end);
      // add 1 more day to ed
      ed.setDate(ed.getDate() + 1);
      ed = ed.getTime();
      var t = s;
      t = t.filter((d) => {
        var time = new Date(d.login_time).getTime();

        return sd < time && time < ed;
      });
      for (var x = 0; x < t.length; x++) {
        tD = tD + t[x].duration;
      }
      var hours = Math.floor(tD / 60);
      var minutes = tD % 60;

      if (hours < 10) {
        hours = "0" + hours;
      }

      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      var dur = hours + ":" + minutes;
      setTotalDuration(dur);
      setRecords(t);
      setBackupRecords(s);
    }
  };

  const getDate = async () => {
    var d = new Date();
    var day = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();
    if (day.toString().length === 1) day = "0" + day;
    if (month.toString().length === 1) month = "0" + month;
    d = year + "-" + month + "-" + day;
    setStartDate(d);
    setEndDate(d);
    return d;
  };

  useEffect(() => {
    var d = getCookie("doctor");
    if (d !== "") {
      d = JSON.parse(d);
    }
    getRecords(d.LM_REFERENCE_ID);
  }, []);

  // {records.map((record, index) => {
  //   var duration = record.duration;
  //   //convert minutes to hh:mm:ss
  //   var hours = Math.floor(duration / 60);
  //   var minutes = duration % 60;

  //   if (hours < 10) {
  //     hours = "0" + hours;
  //   }

  //   if (minutes < 10) {
  //     minutes = "0" + minutes;
  //   }
  //   var dur = hours + ":" + minutes;
  //   var lin = record.login_time.slice(0, 10);
  //   lin = lin.split("-");
  //   lin = lin[2] + "-" + lin[1] + "-" + lin[0];
  //   var lout = record.logout_time.slice(0, 10);
  //   lout = lout.split("-");
  //   lout = lout[2] + "-" + lout[1] + "-" + lout[0];
  //   return (
  //     <tr key={index}>
  //       <td>{index + 1}</td>
  //       <td>{lin}</td>
  //       <td>{record.login_time.slice(11, 19)}</td>
  //       <td>{lout}</td>
  //       <td>{record.logout_time.slice(11, 19)}</td>
  //       <td>{dur}</td>
  //     </tr>
  //   );
  // })}

  const columns = [
    {
      text: "ID",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      sort: true,
      headerStyle: {
        paddingBottom: 20
      }
    },
    {
      dataField: "login_time",
      text: "Log In Date",
      formatter: (cell, row, index) => {
        var lin = cell.slice(0, 10);
        lin = lin.split("-");
        lin = lin[2] + "-" + lin[1] + "-" + lin[0];
        return lin;
      },
      sort: true,
      headerStyle: {
        paddingBottom: 20
      }
    },
    {
      dataField: "login_time",
      text: "Log In Time",
      formatter: (cell, row, index) => {
        var lin = cell.slice(11, 19);
        return lin;
      },
      sort: true,
      headerStyle: {
        paddingBottom: 20
      }
    },
    {
      dataField: "logout_time",
      text: "Log Out Date",
      formatter: (cell, row, index) => {
        var lout = cell.slice(0, 10);
        lout = lout.split("-");
        lout = lout[2] + "-" + lout[1] + "-" + lout[0];
        return lout;
      },
      sort: true,
      headerStyle: {
        paddingBottom: 20
      }
    },
    {
      dataField: "logout_time",
      text: "Log Out Time",
      formatter: (cell, row, index) => {
        var lout = cell.slice(11, 19);
        return lout;
      },
      sort: true,
      headerStyle: {
        paddingBottom: 20
      }
    },
    {
      dataField: "duration",
      text: "Duration", 
      headerFormatter: durationFormatter,
      formatter: (cell, row, index) => {
        var hours = Math.floor(cell / 60);
        var minutes = cell % 60;

        if (hours < 10) {
          hours = "0" + hours;
        }

        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        var dur = hours + ":" + minutes;
        return dur;
      },
      sort: true,
    },
  ];


  function durationFormatter(column, colIndex) {
    var tdur = 0;
    for(var i =0; i<records.length;i++){
      tdur = tdur + records[i].duration;
    };
    var hours = Math.floor(tdur / 60);
        var minutes = tdur % 60;

        if (hours < 10) {
          hours = "0" + hours;
        }

        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        var dur = hours + ":" + minutes;

    return (
      <th>{column.text}  <br /> {dur}</th>
    );
  }
  

  const defaultSorted = [
    {
      dataField: "login_time",
      order: "desc",
    },
  ];

  return (
    <AppDataContext.Consumer>
      {(appData) => (
        <div className="panel">
          <h4>Log In/Out Register</h4>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <span
              style={{
                marginRight: 10,
                position: "relative",
                top: 5,
                fontWeight: 600,
              }}
            >
              FROM -{" "}
            </span>
            <input
              id="startDate"
              style={{
                padding: 5,
              }}
              type="date"
              onChange={(e) => {
                setStartDate(e.target.value);
                filterUsingDate(
                  e.target.value,
                  document.getElementById("endDate").value
                );
              }}
              value={startDate}
            />
            <span
              style={{
                marginRight: 10,
                marginLeft: 10,
                position: "relative",
                top: 5,
                fontWeight: 600,
              }}
            >
              TO -{" "}
            </span>
            <input
              id="endDate"
              style={{
                padding: 5,
              }}
              type="date"
              onChange={(e) => {
                setEndDate(e.target.value);
                filterUsingDate(
                  document.getElementById("startDate").value,
                  e.target.value
                );
              }}
              value={endDate}
            />
            {/* <input
              onClick={async() => {
                var d1 = await getDate();
                var s_start = d1 + " 00:00:00";
                var s_end = d1 + " 23:59:00";
                var sd = new Date(s_start).getTime();
                var ed = new Date(s_end).getTime();
                var t = backupRecords;
                t = t.filter((d) => {
                  var time = new Date(d.login_time).getTime();
            
                  return sd < time && time < ed;
                });
                var tD = 0;
                for(var x =0;x<t.length;x++){
                  tD = tD + t[x].duration;
                }
                var hours = Math.floor(tD / 60);
                var minutes = tD % 60;
                var seconds = 0;
          
                if (hours.toString().length === 1) {
                  hours = "0" + hours;
                }
          
                if (minutes.toString().length === 1) {
                  minutes = "0" + minutes;
                }
          
                if (seconds.toString().length === 1) {
                  seconds = "0" + seconds;
                }
                var dur = hours + ":" + minutes + ":" + seconds;
                setTotalDuration(dur);
                setRecords(t);
              }}
              style={{
                marginLeft: 10,
                marginRight: 10,
                width: 100,
                padding: 5,
                borderRadius: 5,
                border: "1px solid #fff",
                backgroundColor: "#163470",
                color: "#fff",
              }}
              type="button"
              value="Refresh"
            /> */}
          </div>
          <br />
          <BootstrapTable
            keyField="id"
            data={records}
            columns={columns}
            exportCSV={{ onlyExportFiltered: true, exportAll: false }}
            hover
            striped
            condensed
            headerWrapperClasses="next-table-header"
            pagination={paginationFactory()}
            defaultSorted={defaultSorted}
          />
        </div>
      )}
    </AppDataContext.Consumer>
  );
};
