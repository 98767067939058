import { IoSendSharp } from "react-icons/io5";
import { AiOutlineClose, AiOutlinePaperClip } from "react-icons/ai";
import React, { useState, useContext, useCallback, useEffect } from "react";
import { SocketContext } from "../../context/socket";
import { getCookie } from "../../js/cookies";
import { AppDataContext } from "../../context/AppContext";
import { saveMessageDB } from "../../js/convo";
import { saveAttachmentDB } from "../../js/convo";
import CircularProgress from '@mui/material/CircularProgress';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import Compressor from "compressorjs";


export const ChatScreen = (props) => {
  const [sendActive, setSendActive] = useState(false);

  const appContext = useContext(AppDataContext);

  const { height } = props;

  const socket = useContext(SocketContext);

  const [messages, setMessages] = useState([]);

  const [file, setFile] = useState(null);

  const [doctor, setDoctor] = useState({});

  const [sending, setSending] = useState(false);

  const [chatHeight, setChatHeight] = useState(0);

  var l_messages = [];

  useEffect(() => {
    var objDiv = document.getElementById("chat-view");
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    var doctor = getCookie("doctor");

    if (doctor !== "") {
      doctor = JSON.parse(doctor);
      setDoctor(doctor);
    }
  }, []);

  // const compressPDF = async (file) => {
  //   const pdfDoc = await PDFDocument.load(file);

  //   // Get all existing pages
  //   const pages = pdfDoc.getPages();

  //   // Iterate through each page and compress its content
  //   for (let i = 0; i < pages.length; i++) {
  //     const page = pages[i];
  //     const contentStream = page.getOperatorList();

  //     // Compress the content stream
  //     const compressedContentStream = compressContentStream(contentStream);

  //     // Update the page with the compressed content stream
  //     page.setOperatorList(compressedContentStream);
  //   }

  //   // Save the compressed PDF as a new file
  //   const compressedPdfBytes = await pdfDoc.save();

  //   // Perform any required actions with the compressed PDF bytes
  //   // e.g., send the file to the server or download it locally
  // };
  return (
    <AppDataContext.Consumer>
      {(appData) => (
        <div
          style={{
            "--height": height + "px",
            display: props.fromMob ? (props.isChat ? "flex" : "none") : "flex",
          }}
          id="chatCont"
          className={props.fromMob ? "chat-mobile-cont" : "chat-cont"}
        >
          <div id="chat-view" className="chat-view">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                minHeight: "100%",
                justifyContent: "flex-end",
              }}
              className="chat-bubble-cont"
            >
              {appData["messages"].map((message, index) => {
                return (
                  <ChatBubble
                    lm_reference_id={doctor.LM_REFERENCE_ID}
                    key={index}
                    message={message}
                  />
                );
              })}
            </div>
          </div>
          <div className="chat-input">
            <div className="input-cont">
              <input
                disabled={file !== null}
                onKeyUp={async (e) => {
                  if (!sending && e.target.value !== "") {
                    if (e.key === "Enter") {
                      if (file === null) {
                        setSending(true);
                        var msg = {
                          message_id: Math.floor(
                            Math.random() * 1000000000
                          ).toString(),
                          sender_id: doctor.LM_REFERENCE_ID,
                          message: e.target.value,
                          time: new Date().getTime(),
                          room_id: props.roomId,
                          is_file: false,
                        };
                        e.target.value = "";
                        var res = await saveMessageDB(msg);
                        setMessages([...messages, msg]);

                        appData.addMessage(msg);
                        socket.emit("sendMessageRoom", {
                          msg: msg,
                          room: props.roomId,
                        });
                        setSendActive(false);
                        setSending(false);
                      } else {
                        setSending(true);
                        var msg2 = {
                          message_id: Math.floor(
                            Math.random() * 1000000000
                          ).toString(),
                          sender_id: doctor.LM_REFERENCE_ID,
                          file_type: file.type,
                          time: new Date().getTime(),
                          file_name: file.name,
                          room_id: props.roomId,
                          file: file,
                          is_file: true,
                        };
                        var resl = await saveAttachmentDB(msg2);
                        msg2.file_url = resl.message.message;
                        setMessages([...messages, msg2]);

                        appData.addMessage(msg);
                        delete msg2.file;
                        socket.emit("sendMessageRoom", {
                          msg: msg2,
                          room: props.roomId,
                        });

                        e.target.value = "";
                        setSendActive(false);
                        setFile(null);
                        setSending(false);
                      }
                    }
                  }
                }}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setSendActive(true);
                  } else {
                    setSendActive(false);
                  }
                }}
                type="text"
                id="chatMessage"
                placeholder="Enter your message here..."
              />
              <div className="message-opt">
                <input
                  accept="image/*,application/pdf"
                  style={{
                    display: "none",
                  }}
                  onChange={async (e) => {

                    if (e.target.files[0].type.includes("image")) {
                      console.log("ss")

                      var upload_image = "";

                      const reader = new FileReader();
                      reader.addEventListener("load", () => {
                        upload_image = reader.result;
                        console.log(upload_image);
                        // convert uri to blob
                        fetch(upload_image)
                          .then((res) => res.blob())
                          .then((blob) => {
                            var file = new File([blob], e.target.files[0].name, {
                              type: e.target.files[0].type,
                            });
                            setFile(file);
                            document.getElementById("chatMessage").value = e.target.files[0].name;
                            setSendActive(true);
                          }
                          );
                      });
                      const image = e.target.files[0];
                      const max_size = 1000000;
                      const max_width = 2000;
                      const max_height = 2000;
                      const compress_image = new Compressor(image, {
                        quality: 0.6,
                        maxWidth: max_width,
                        maxHeight: max_height,
                        convertSize: max_size,
                        success: (result) => {
                          const file = new File([result], result.name, {
                            type: "image/jpeg",
                          });

                          reader.readAsDataURL(file);
                        },
                      });
                    } else {
                      const file = e.target.files[0];
                      var upload_image = "";
                      setFile(e.target.files[0]);
                      document.getElementById("chatMessage").value = e.target.files[0].name;
                      setSendActive(true);
                    }

                  }}
                  type="file"
                  name="attach"
                  id="attach"
                />
                {file === null ? (
                  <AiOutlinePaperClip
                    onClick={() => {
                      document.getElementById("attach").click();
                    }}
                    style={{
                      color: "#2D56A9",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <AiOutlineClose
                    onClick={() => {
                      document.getElementById("chatMessage").value = "";
                      setFile(null);
                      setSendActive(false);
                    }}
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                  />
                )}
                <IoSendSharp
                  onClick={async () => {
                    if (file === null) {
                      var m = document.getElementById("chatMessage").value;
                      if (!sending && m !== "") {
                        setSending(true);
                        var msg = {
                          message_id: Math.floor(
                            Math.random() * 1000000000
                          ).toString(),
                          sender_id: doctor.LM_REFERENCE_ID,
                          message: m,
                          time: new Date().getTime(),
                          room_id: props.roomId,
                          is_file: false,
                        };
                        document.getElementById("chatMessage").value = "";
                        var res = await saveMessageDB(msg);
                        setMessages([...messages, msg]);
                        appData.addMessage(msg);
                        socket.emit("sendMessageRoom", {
                          msg: msg,
                          room: props.roomId,
                        });
                        setSendActive(false);
                        setSending(false);
                      }
                    } else {
                      setSending(true);
                      var msg2 = {
                        message_id: Math.floor(
                          Math.random() * 1000000000
                        ).toString(),
                        sender_id: doctor.LM_REFERENCE_ID,
                        file_type: file.type,
                        file_name: file.name,
                        file: file,
                        time: new Date().getTime(),
                        room_id: props.roomId,
                        is_file: true,
                      };
                      var res2 = await saveAttachmentDB(msg2);
                      msg2.file_url = res2.message.message;
                      setMessages([...messages, msg2]);
                      appData.addMessage(msg2);
                      delete msg2.file;
                      socket.emit("sendMessageRoom", {
                        msg: msg2,
                        room: props.roomId,
                      });
                      document.getElementById("chatMessage").value = "";
                      setFile(null);
                      setSendActive(false);
                      setSending(false);
                    }
                  }}
                  style={{
                    color: sendActive ? "#2D56A9" : "#6d6d6d",
                    cursor: sendActive ? "pointer" : "",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </AppDataContext.Consumer>
  );
};

const ChatBubble = (props) => {
  const appContext = useContext(AppDataContext);

  return (
    <div
      style={{
        justifyContent:
          props.message.sender_id === props.lm_reference_id ? "flex-end" : "flex-start",
      }}
      className="chat-bubble"
    >
      <div
        style={{
          backgroundColor:
            props.message.sender_id === props.lm_reference_id ? "#2D56A9" : "#6d6d6d",
          margin: '0 10px',
          borderRadius: 13
        }}
      >
        {
          props.message.is_file ? <DocViewer message={props.message} /> : <p>
            {props.message.message}

            <span>
              {new Date(props.message.time).getHours() +
                ":" +
                new Date(props.message.time).getMinutes()}
            </span>
          </p>
        }
      </div>
    </div>
  );
};


const DocViewer = (props) => {
  return <div>
    {
      props.message.file_type.includes("image") ? <>
        <img onClick={() => {
          window.open(props.message.file_url, '_blank');
        }} style={{
          height: 200,
          width: 200,
          margin: 10,
          cursor: 'pointer',
          borderRadius: 10
        }} id="chat-image" src={props.message.file_url} alt={props.message.file_name} /> <br /></> : <p style={{
          marginBottom: -10,
          color: '#fff'
        }}><a style={{
          color: '#fff'
        }} target='_blank' rel='noreferrer' href={props.message.file_url}>{props.message.file_name}</a></p>
    }
    <div style={{
      width: '100%',
      textAlign: 'right',
      paddingRight: 10,
    }}>
      <span style={{
        fontSize: '11px'
      }}>
        {new Date(props.message.time).getHours() +
          ":" +
          new Date(props.message.time).getMinutes()}
      </span>
    </div>
  </div>
}