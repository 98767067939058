import { useContext, useEffect, useRef, useState } from "react";
import { IoMdCall } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  getCallHistoryByLmId,
  getLedgerMasterById,
  getPrescriptionByPId,
  getRoomToken,
  updateCallLog,
  updatePrescription,
} from "../../../js/convo";
import { getCookie } from "../../../js/cookies";
import { MdEdit, MdPhoneCallback } from "react-icons/md";
import { AppDataContext } from "../../../context/AppContext";
import { PrescriptionScreen } from "../../conversation/PrescriptionScreen";
import { getItemsId, getTypeId, getAgoraToken } from "../../../js/convo";
import { UpdatePrescription } from "../../conversation/UpdatePrescription";
import { BiUpload } from "react-icons/bi";
import { SocketContext } from "../../../context/socket";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ZIM } from "zego-zim-web";
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { sendPushNotification } from "../../../js/auth";


export const CallHistory = () => {
  const nav = useNavigate();

  const [doctor, setDoctor] = useState({});

  const [startDate, setStartDate] = useState("");

  const appContext = useContext(AppDataContext);

  const [departments, setDepartments] = useState([]);

  const [itemMasters, setItemMasters] = useState([]);

  const [typeMaster, setTypeMaster] = useState([]);

  const [open, setOpen] = useState(false);

  const [endDate, setEndDate] = useState("");

  const [backupCallLogs, setBackupCallLogs] = useState([]);

  const socket = useContext(SocketContext);

  const [patient, setPatient] = useState({});

  const [prescription, setPrescription] = useState(null);



  const closeButton = () => {
    setPatient({});
    appContext.setCurrentPrescription({});
    var o = document.querySelector(".prescription-cont-outside");
    o.classList.remove("show");
    setOpen(false);
  };

  const getDate = async () => {
    var d = new Date();
    var day = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();
    if (day.toString().length === 1) day = "0" + day;
    if (month.toString().length === 1) month = "0" + month;
    d = year + "-" + month + "-" + day;
    setStartDate(d);
    setEndDate(d);
    return d;
  };

  const filterUsingDate = (start, endD) => {
    console.log("filtered==========>");
    var s_start = start;
    var s_end = endD;
    var sd = new Date(s_start).getTime();
    var ed = new Date(s_end);
    // add 1 more day to ed
    ed.setDate(ed.getDate() + 1);
    ed = ed.getTime();
    var t = backupCallLogs;
    t = t.filter((d) => {
      var time = new Date(d.date).getTime();
      return sd < time && time < ed;
    });
    appContext.setCallLogs(t);
  };

  const getCallHistory = async (lm_reference_id, start, end) => {
    var res = await getCallHistoryByLmId(lm_reference_id);
    if (res.success) {
      var t_calls = res.callLog;
      setBackupCallLogs(t_calls);
      var s_start = start === null ? await getDate() : start;
      console.log("Start=======================>");
      console.log(s_start);
      var s_end = end === null ? await getDate() : end;
      var sd = new Date(s_start).getTime();
      var ed = new Date(s_end);
      // add 1 more day to ed
      ed.setDate(ed.getDate() + 1);
      ed = ed.getTime();
      var t = t_calls;
      t = t.filter((d) => {
        var time = new Date(d.date).getTime();
        return sd < time && time < ed;
      });
      console.log(t)
      appContext.setCallLogs(t);
    }
  };


  const getDepartmentAndTest = async () => {
    var res = await getItemsId();
    if (res.success) {
      var t = res.itemMaster.filter((item) => item.IS_ACTIVE === true);
      setItemMasters(t);
    }
    var res2 = await getTypeId();
    if (res2.success) {
      var t1 = res2.typeMaster.filter((item) => item.IS_ACTIVE === true);
      var dpt_t = t1.filter((item) => item.MASTER_NAME === "Department");
      setTypeMaster(t1);
      // get all TYPE_NAME which have MASTER_NAME as "Department"
      var deps = [];
      for (var i = 1; i < dpt_t.length; i++) {
        deps.push(dpt_t[i].TYPE_NAME);
      }
      setDepartments(deps);
    }
  };



  // const sendInvite = async(roomId, profile, call) => {
  //   console.log(profile)
  //   var d = getCookie("doctor");
  //   d = JSON.parse(d);
  //   console.log(doctor)
  //   const userID = d.LM_REFERENCE_ID.toString();
  //   console.log('============================>userid')
  //   console.log(userID)
  //   const userName = 'LiveOPD';
  //   const appID = 1516978750;
  //   const serverSecret = "0202ff61b9255174081f3364bb4b964c";
  //   const TOKEN = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomId, userID, userName);
  //   const zp =  ZegoUIKitPrebuilt.create(TOKEN);
  //   zp.addPlugins({ ZIM });
  //   const targetUser = {
  //     userID: profile.LM_REFERENCE_ID.toString(),
  //     userName: profile.LM_NAME
  //   };
  //   console.log(targetUser);
  //   var mmap ={
  //     roomId,
  //     profile,
  //     call,
  //   }
  //   zp.sendCallInvitation({
  //     callees: [targetUser],
  //     data: JSON.stringify(mmap),
  //     callType: ZegoUIKitPrebuilt.InvitationTypeVideoCall,
  //     timeout: 60, // Timeout duration (second). 60s by default, range from [1-600s].
  //   }).then((res) => {
  //     console.warn(res);
  //     return true;
  //   })
  //     .catch((err) => {
  //       console.warn(err);
  //       return false;
  //     });
  // }


  useEffect(() => {
    var d = getCookie("doctor");
    if (d !== "") {
      console.log(JSON.parse(d))
      setDoctor(JSON.parse(d));

      getCallHistory(JSON.parse(d).LM_REFERENCE_ID, null, null);
    }
    getDepartmentAndTest();
  }, []);



  const [orderType, setOrderType] = useState(null);

  // var date = call.date.slice(0, 10);
  // var day = date.slice(8, 10);
  // var month = date.slice(5, 7); 
  // var year = date.slice(0, 4);
  // var dateDDMMYYYY = day + "-" + month + "-" + year;
  // return (
  //   <tr key={index}>
  //     <td>{index + 1}</td>
  //     <td>{call.status}</td>
  //     <td>{call.patient_name}</td>
  //     <td>{call.ring_time}</td>
  //     <td>{call.start_time}</td>
  //     <td>{call.end_time}</td>
  //     <td>{call.duration}</td>
  //     <td>{dateDDMMYYYY}</td>

  const columns = [
    {
      text: "ID",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell, row, index) => {
        return <p style={{
          fontWeight: 600,
          color: cell === "Missed Call" ? "red" : "green"
        }}>{cell}</p>
      }
    },
    {
      dataField: "patient_name",
      text: "Patient Name",
      sort: true,
    },
    {
      dataField: "ring_time",
      text: "Ring Time",
      sort: true,
    },
    {
      dataField: "start_time",
      text: "Start Time",
      sort: true,
    },
    {
      dataField: "end_time",
      text: "End Time",
      sort: true,
    },
    {
      dataField: "duration",
      text: "Duration",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell, row, index) => {
        var date = cell.slice(0, 10);
        var day = date.slice(8, 10);
        var month = date.slice(5, 7);
        var year = date.slice(0, 4);
        var dateDDMMYYYY = day + "-" + month + "-" + year;
        return dateDDMMYYYY;
      },
      sort: true,
    },
    {
      dataField: "actions",
      text: "ACTIONS",
      isDummyField: true,
      csvExport: false,
      formatter: (cell, call, index) => {
        var call_start_time = call.start_time;
        var call_date = call.date.slice(0, 10);
        var ftime = call_date + " " + call_start_time;
        var call_start_time_obj = new Date(ftime);
        // if 24 hours have passed since the call, then disable the call back button
        var d = new Date();
        var diff = (d.getTime() - call_start_time_obj.getTime()) / 1000;
        diff /= (60 * 60);
        var diffInHours = Math.abs(Math.round(diff));
        return <AppDataContext.Consumer>
          {
            insideData => <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {call.status !== "Missed Call" &&
                !call.prescription_uploaded &&
                call.prescription_id !== null && (
                  <MdEdit
                    onClick={() => {
                      var p = {
                        lm_reference_id: call.patient_id,
                        name: call.patient_name,
                      };
                      setPrescription(call);
                      setPatient(p);
                      var o = document.querySelector(".prescription-cont-outside");
                      o.classList.add("show");
                      setOpen(true);
                    }}
                    style={{
                      fontSize: 25,
                      position: "relative",
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                  />
                )}

              {
                diffInHours <= 24 && <MdPhoneCallback
                  onClick={async () => {
                    if (insideData.isOnline) {
                      var res = await getLedgerMasterById(call.patient_id);
                      console.log(res);
                      var d = new Date();
                      var timeD1 = d.getTime();
                      var h = d.getHours();
                      var m = d.getMinutes();
                      var s = d.getSeconds();
                      if (h.toString().length === 1) h = "0" + h;
                      if (m.toString().length === 1) m = "0" + m;
                      if (s.toString().length === 1) s = "0" + s;
                      var time = h + ":" + m + ":" + s;
                      if (res.success) {
                        var roomId = "room" + Math.floor(Math.random() * 1000000000).toString();
                        var map = {
                          lm_reference_id: call.patient_id,
                          name: call.patient_name,
                          cred: res.data.LM_MOBILE,
                          dob: res.data.LM_DOB,
                          sex: res.data.LM_SEX,
                          photo: res.data.LM_PHOTO,
                          queueStartTime: time,
                          bloodGroup: res.data.BLOOD_GROUP,
                          maritalStatus: res.data.MARITIAL_STATUS,
                          refNo: res.data.LM_REFERENCE_ID,
                          roomId: roomId,
                          status: "Outgoing"
                        };
                        console.log(map);
                        socket.emit("onVideoCallToRoom", {
                          room: res.data.LM_REFERENCE_ID,
                          channelName: roomId,
                          profile: res.data,
                          queueStartTime: time,
                          doctor: JSON.parse(getCookie("doctor")),
                          queueStartTimeObject: timeD1,
                        });


                        var data = {
                          patient: JSON.stringify(res.data),
                          call: JSON.stringify(map),
                          doctor: getCookie("doctor"),
                          queueStartTimeObject: timeD1.toString(),
                        }

                        sendPushNotification(data, res.data.LM_MAIN_ID);
                        // var res = await sendInvite(roomId, res.data, map);

                        var t_doctor = JSON.parse(getCookie("doctor"));

                        appContext.setInCall(true);
                        nav("/room_cb/" + roomId, {
                          state: {
                            lm_reference_id: t_doctor.LM_REFERENCE_ID,
                            doctor: t_doctor,
                            patient: map,
                            reference_id: res.data.LM_REFERENCE_ID,
                          },
                        });
                      } else {
                        alert(
                          "Patient data was unable to fetch. Please try again later."
                        );
                        return;
                      }
                    } else {
                      alert(
                        "You are offline. Please set your status to online to call back."
                      );
                    }
                  }}
                  style={{
                    fontSize: 25,
                    position: "relative",
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                />
              }


              {!call.prescription_uploaded && call.prescription_id !== null && (
                <BiUpload
                  onClick={async () => {
                    if (call.prescription_id !== null) {
                      var prompt_ans = window.confirm(
                        "Do you want to upload the prescription?"
                      );
                      if (prompt_ans) {
                        var res2 = await getPrescriptionByPId(call.prescription_id);
                        if (res2.success) {
                          var map = {};
                          var bill = res2.bill;
                          var diagnosis = res2.diagnosis;
                          var investigations = res2.investigations;
                          var prescription = res2.prescriptions;
                          delete bill.createdAt;
                          delete bill.updatedAt;
                          delete bill.id;
                          map = { ...bill };
                          delete diagnosis.createdAt;
                          delete diagnosis.updatedAt;
                          delete diagnosis.id;
                          delete diagnosis.prescription_id;
                          map = { ...map, ...diagnosis };
                          map.investigations = investigations;
                          map.prescriptions = prescription;
                          map.print_pdf = true;
                          console.log(map);
                          var res = await updatePrescription(
                            call.prescription_id,
                            map
                          );
                          if (res.success) {
                            var res3 = await updateCallLog(call.room_id, {
                              prescription_uploaded: true,
                              prescription_id: call.prescription_id
                            });

                            if (res3.success) {
                              await getCallHistory(doctor.LM_REFERENCE_ID, startDate, endDate)
                              alert("Prescription uploaded successfully");
                            }
                            else {
                              alert("Prescription upload failed. Please try again later.");
                            }



                          } else {
                            alert(
                              "Prescription upload failed. Please try again later."
                            );
                          }
                        } else {
                          alert(
                            "Prescription was unable to fetch. Please try again later."
                          );
                        }
                      }
                    }
                  }}
                  style={{
                    fontSize: 25,
                    position: "relative",
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
          }
        </AppDataContext.Consumer>
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "start_time",
      order: "desc",
    },
  ];

  return (
    <AppDataContext.Consumer>
      {(appData) => (
        <div>
          <div
            style={{
              height: "100vh",
              position: "fixed",
              zIndex: 2,
              right: 0,
              paddingTop: 70,
              boxShadow: "0 0 8px gray",
              backgroundColor: "#fff",
            }}
            className="prescription-cont-outside"
          >
            {open && (
              <UpdatePrescription
                patient={patient}
                closeBtn={true}
                closeBtnFunction={() => {
                  closeButton();
                  getCallHistory(doctor.LM_REFERENCE_ID, startDate, endDate)
                }}
                departments={departments}
                itemMasters={itemMasters}
                typeMaster={typeMaster}
                presId={prescription.prescription_id}
                roomId={prescription.room_id}
                lm_reference_id={doctor.LM_REFERENCE_ID}
              />
            )}
          </div>
          <div className="panel">
            <h4
              style={{
                fontWeight: "bolder",
              }}
            >
              Call History
            </h4>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <span
                style={{
                  marginRight: 10,
                  position: "relative",
                  top: 5,
                  fontWeight: 600,
                }}
              >
                FROM -{" "}
              </span>
              <input
                id="startDate"
                style={{
                  padding: 5,
                }}
                type="date"
                onChange={(e) => {
                  setStartDate(e.target.value);
                  filterUsingDate(
                    e.target.value,
                    document.getElementById("endDate").value
                  );
                }}
                value={startDate}
              />
              <span
                style={{
                  marginRight: 10,
                  marginLeft: 10,
                  position: "relative",
                  top: 5,
                  fontWeight: 600,
                }}
              >
                TO -{" "}
              </span>
              <input
                id="endDate"
                style={{
                  padding: 5,
                }}
                type="date"
                onChange={(e) => {
                  setEndDate(e.target.value);
                  filterUsingDate(
                    document.getElementById("startDate").value,
                    e.target.value
                  );
                }}
                value={endDate}
              />
            </div>
            <br />
            <BootstrapTable
              keyField="id"
              data={appData.callLogs}
              columns={columns}
              exportCSV={{ onlyExportFiltered: true, exportAll: false }}
              hover
              striped
              condensed
              headerWrapperClasses="next-table-header"
              pagination={paginationFactory()}
              defaultSorted={defaultSorted}
            />
          </div>
        </div>
      )}
    </AppDataContext.Consumer>
  );
};
