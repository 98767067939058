import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { createCookie } from "../../js/cookies";
import { BsFillPersonFill } from "react-icons/bs";
import { firebase, auth } from "../../firebaseConfig";
import { isDoctor } from "../../js/auth";
import CircularProgress from '@mui/material/CircularProgress';
import { createLogRegister } from "../../js/convo";

const Login = () => {
  const [done, setDone] = useState(false);

  const [isOtp, setIsOtp] = useState(false);

  const [final, setFinal] = useState("");

  const [doctor, setDoctor] = useState({});

  const [loading, setLoading] = useState(false);

  const [otpLoading, setOtpLoading] = useState(false);

  var url = useLocation().pathname;

  const handleAuth = async () => {
    var phone = document.getElementById("phone").value;

    if (phone.length !== 10) {
      alert("Invalid Phone Number");
      return;
    }

    setLoading(true);
    var res = await isDoctor(phone);
    if(res.success){
      if(res.isDoctor){
        if(res.lm.IS_ACTIVE){
          var doctor = res.lm;
          console.log(doctor)
        setDoctor(doctor);
        var full_phone = "+91" + phone;
        // setLoading(false);
        // setIsOtp(true);
        let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
        auth
          .signInWithPhoneNumber(full_phone, verify)
          .then((result) => {
            setLoading(false);
            setFinal(result);
            setIsOtp(true);
          })
          .catch((err) => {
            setLoading(false);
            setDoctor({});
            console.log(err)
            alert(err);
          });
        } else{
          alert("You are not authorized to login. Please contact administrator.");
          setLoading(false);
        }
      } else{
        alert("This number is not registered as a doctor.");
        setLoading(false);
      }
    }
    else{
      if(res.message === 'Account not found'){
        alert("This number is not registered as a doctor.");
        setLoading(false);
      }
      else{
        alert("Something went wrong! Please try again.");
        setLoading(false);
      }
    }
  };

  const handleOtp = async (otp) => {
    if (otp === null || final === null) return;
    // createCookie("doctor", JSON.stringify(doctor), 1);
    // alert("Login Successfull");
    // window.location.reload();
    setOtpLoading(true);
    final
      .confirm(otp)
      .then((result) => {
        setOtpLoading(false);
        createCookie("doctor", JSON.stringify(doctor), 1);
        alert("Login Successfull");
      })
      .catch((err) => {
        setOtpLoading(false);
        alert("Wrong code");
        setDoctor({});
      });
  };

  useEffect(() => {
    if (!done) {
      if (url !== "/") {
        console.log("Redirecting=======================>")
        window.location.replace("/");
        setDone(true);
      }
    }
  }, []);

  return (
    <section id="auth">
      <div className="left">
        <div className="left-cont">
          <img src={process.env.PUBLIC_URL + "assets/logo_new.png"} alt="" />
          <div className="left-center">
            <h1>LiveOPD Healthcare</h1>
            <h6>Welcome to Doctor's Panel</h6>
          </div>
          <div className="left-bottom">
            <img src={process.env.PUBLIC_URL + "assets/doctor.svg"} alt="" />
          </div>
        </div>
      </div>

      {isOtp ? (
        <VerifyScreen loading={otpLoading} handleOtp={handleOtp} />
      ) : (
        <LoginInputs loading={loading} handleAuth={handleAuth} />
      )}
    </section>
  );
};

const LoginInputs = (props) => {
  return (
    <div className="right">
      <div className="right-cont">
        <div className="mb-logo">
          <img src={process.env.PUBLIC_URL + "assets/logo_new.png"} alt="" />
        </div>
        <br />
        <br />
        <h4>Doctor Login</h4>
        <br />
        <br />
        <div className="auth-form-cont">
          <div
            style={{
              display: "flex",
            }}
          >
            <BsFillPersonFill
              style={{
                fontSize: "1.7rem",
                position: "relative",
                top: 13,
              }}
            />
            <label htmlFor="phone">
              <input
              maxLength={10}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    props.handleAuth();
                  }
                }}
                placeholder="Doctor's Phone"
                type="text"
                name="phone"
                id="phone"
              />
            </label>
          </div>
          <div style={{
            marginTop: 20
          }} id="recaptcha-container">
          </div>

          <button  style={{ borderRadius: 8 }}
            onClick={() => {
              props.handleAuth();
            }}
            className="auth-btn">
            {props.loading ? (
              <CircularProgress size={25} style={{ color: "white", marginTop: 5}} />
            ) : (
              "Login"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const VerifyScreen = (props) => {
  var ref1 = useRef();
  var ref2 = useRef();
  var ref3 = useRef();
  var ref4 = useRef();
  var ref5 = useRef();
  var ref6 = useRef();
  var otp = [];
  return (
    <div className="right">
      <div className="right-cont">
        <div className="mb-logo">
          <img src={process.env.PUBLIC_URL + "assets/logo_new.png"} alt="" />
        </div>
        <br />
        <br />
        <h4>Verify OTP</h4>
        <br />
        <br />
        <div className="auth-form-cont">
          <div className="row otp-cont">
            <div className="col otp-col">
              <input
                ref={ref1}
                onKeyUp={(e) => {
                  if (
                    otp[0] !== "" &&
                    otp[0] !== null &&
                    otp[0] !== undefined
                  ) {
                    if(e.key >= 0 && e.key < 10){
                      ref2.current.focus();
                    }
                  }
                }}
                onChange={(e) => {
                  otp[0] = e.target.value;
                  
                }}
                autoFocus
                maxLength={1}
                className="otp-box"
                type="number"
              />
            </div>
            <div className="col otp-col">
              <input
                ref={ref2}
                onKeyUp={(e) => {
                  if (e.key === "Backspace") {
                    ref1.current.focus();
                  }
                  if (
                    otp[1] !== "" &&
                    otp[1] !== null &&
                    otp[1] !== undefined
                  ) {
                    if(e.key >= 0 && e.key < 10){
                      ref3.current.focus();
                    }
                  }
                }}
                onChange={(e) => {
                  otp[1] = e.target.value;
                  
                }}
                maxLength={1}
                className="otp-box"
                type="text"
              />
            </div>
            <div className="col otp-col">
              <input
                ref={ref3}
                onKeyUp={(e) => {
                  if (e.key === "Backspace") {
                    ref2.current.focus();
                  }
                  if (
                    otp[2] !== "" &&
                    otp[2] !== null &&
                    otp[2] !== undefined
                  ) {
                    if(e.key >= 0 && e.key < 10){
                      ref4.current.focus();
                    }
                  }
                }}
                onChange={(e) => {
                  otp[2] = e.target.value;
                  
                }}
                maxLength={1}
                className="otp-box"
                type="text"
              />
            </div>
            <div className="col otp-col">
              <input
                ref={ref4}
                onKeyUp={(e) => {
                  if (e.key === "Backspace") {
                    ref3.current.focus();
                  }
                  if (
                    otp[3] !== "" &&
                    otp[3] !== null &&
                    otp[3] !== undefined
                  ) {
                    if(e.key >= 0 && e.key < 10){
                      ref5.current.focus();
                    }
                  }
                }}
                onChange={(e) => {
                  otp[3] = e.target.value;
                  
                }}
                maxLength={1}
                className="otp-box"
                type="text"
              />
            </div>
            <div className="col otp-col">
              <input
                ref={ref5}
                onKeyUp={(e) => {
                  if (e.key === "Backspace") {
                    ref4.current.focus();
                  }
                  if (
                    otp[4] !== "" &&
                    otp[4] !== null &&
                    otp[4] !== undefined
                  ) {
                    ref6.current.focus();
                  }
                }}
                onChange={(e) => {
                  otp[4] = e.target.value;
                  
                }}
                maxLength={1}
                className="otp-box"
                type="text"
              />
            </div>
            <div className="col otp-col">
              <input
                ref={ref6}
                
                onChange={(e) => {
                  otp[5] = e.target.value;

                  
                }}
                onKeyUp={(e) => {
                  if (e.key === "Backspace") {
                    ref5.current.focus();
                  }
                }}
                maxLength={1}
                className="otp-box"
                type="text"
              />
            </div>
          </div>
          <button  style={{ borderRadius: 8 }}
            onClick={() => {
              var string_otp = otp.join("");
              props.handleOtp(string_otp);
            }}
            className="auth-btn">
            {props.loading ? (
               <CircularProgress size={25} style={{ color: "white", marginTop: 5}} />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
