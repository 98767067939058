import { useContext, useEffect, useState } from "react";
import { IoMdCall } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createPrescription,
  deleteCallQueue,
  getAgoraDet,
  getAllCallQueue,
  getRoomToken,
} from "../../../js/convo";
import { deleteCookie, getCookie } from "../../../js/cookies";
import { AppDataContext } from "../../../context/AppContext";
import { SocketContext } from "../../../context/socket";

export const CallQueue = () => {
  const nav = useNavigate();

  const [doctor, setDoctor] = useState({});

  const socket = useContext(SocketContext);

  const appContext = useContext(AppDataContext);
  const startRoom = async (roomId, patient) => {
    var res = await getAgoraDet('7cd4f829944b46e4baa3c113058364e4', roomId);
    console.log(res)
    if(res.success){
      appContext.setInCall(true);
    socket.emit("onCallAttended", {
      lm_reference_id: patient.lm_reference_id,
    });
    var c = appContext.callQueue;
    c = c.filter((call) => call.lm_reference_id === patient.lm_reference_id);
    var s = c[0].lm_reference_id;
    await deleteCallQueue(s);
    // socket.emit("joinRoom",{
    //   room_id: roomId,
    // })
    nav("/room/" + roomId, {
      state: {
        lm_reference_id: doctor.LM_REFERENCE_ID,
        doctor: doctor,
        patient: patient,
      },
    });
    }
  };

  const getCallQueues = async () => {
    var res = await getAllCallQueue();
    if (res.callQueue) {
      appContext.setCallQueue(res.callQueue);
    }
  };

  useEffect(() => {
    var d = getCookie("doctor");
    if (d !== "") {
      setDoctor(JSON.parse(d));
      d = JSON.parse(d);
    }
    if (appContext.isOnline) {
      getCallQueues();
    } else {
      appContext.setCallQueue([]);
    }
  }, [appContext.isOnline]);

  useEffect(() => {
    socket.on("callQueue", (data) => {
      getCallQueues();
    });

    socket.on("callAttended", async (data) => {
      var t = appContext.callQueue;
      var s = t.filter((call) => call.lm_reference_id === data.lm_reference_id);

      var d_id = s[0].lm_reference_id;
      t = t.filter((call) => call.lm_reference_id !== data.lm_reference_id);
      appContext.setCallQueue(t);
      await deleteCallQueue(d_id);
    });
  }, [socket, appContext.callQueue, appContext]);

  useEffect(() => {
    appContext.resetAll();
  }, []);

  const location = useLocation();

  const saveCrashedPres = async() => {
    var pres = getCookie("prescription");
    if(pres !== ""){
      pres = JSON.parse(pres);
      var res = await createPrescription(pres);
      deleteCookie("prescription");
    }
  }


  useEffect(() => {
    if(location.pathname === "/callQueue"){
      saveCrashedPres();
    }
  }, [location]);

  return (
    <AppDataContext.Consumer>
      {(appData) => (
        <div className="panel">
          <h4
            style={{
              fontWeight: "bolder",
            }}
          >
            Calls in queue - {appData.callQueue.length}
          </h4>
          {appData.isOnline ? (
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Queue No</th>
                  <th>Reference No</th>
                  <th>Name</th>
                  <th>Phone/Email</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {appData.callQueue.map((call, index) => {
                  return (
                    <tr key={index}>
                      <td>{"#" + (index + 1)}</td>
                      <td>{call.refNo}</td>
                      <td>{call.name}</td>
                      <td>{call.cred}</td>
                      <td
                        style={{
                          paddingRight: 20,
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          onClick={(e) => {
                            call.status = "Incoming";
                            startRoom(call.roomId, call);
                          }}
                          className="attend-call"
                        >
                          <IoMdCall />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                height: "90vh",
              }}
            >
              <h4
                style={{
                  fontWeight: "bolder",
                  fontSize: 40,
                  color: "#b1b1b1",
                  textAlign: "center",
                }}
              >
                You are <br />
                Offline
              </h4>
              <p
                style={{
                  fontWeight: "bolder",
                  color: "#b1b1b1",
                  textAlign: "center",
                }}
              >
                Switch to online mode to view calls
              </p>
            </div>
          )}
        </div>
      )}
    </AppDataContext.Consumer>
  );
};
