import React from "react";
import {io} from "socket.io-client";

export const socket = io("https://server.liveopd.in", {
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: Infinity,
    randomizationFactor: 0.5,   
});

socket.on("connect", () => {
    console.log("connected to socket");
});


socket.on("disconnect", () => {
    console.log("disconnected from socket");
});

socket.on("reconnect", () => console.log("reconnected to socket"));

export const SocketContext = React.createContext();