import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import '../src/css/Auth.css';
import '../src/css/Panel.css';
import '../src/css/AdminPanel.css';
import '../src/css/Room.css';
import RouterController from './components/RouterController';
import {SocketContext, socket} from './context/socket';
import { AppDataContext, AppDataProvider } from './context/AppContext';
import config from './firebaseConfig';

function App() {
  return (
    <div className="App">
      <AppDataProvider>
      <SocketContext.Provider value={socket}>
           <RouterController />
      </SocketContext.Provider>
      </AppDataProvider>
    </div>
  );
}

export default App;
