import React from 'react';

const AppDataContext = React.createContext();

class AppDataProvider extends React.Component {

    state = {
        messages: [],
        callLogs: [],
        callQueue: [],
        isOnline: false,
        inCall: false,
        currentPrescription: {},
        backupPrescription: {},
        prevVisits: [],
        selectedIndex: 0,
        todaysIndex: 0,
        prevPrescriptions: [],
        timeInterval: null,
        socketStable: true
    };

    resetAll = () => {
        this.setState({
            messages: [],
            currentPrescription: {},
            backupPrescription: {},
            prevVisits: [],
            selectedIndex: 0,
            todaysIndex: 0,
            prevPrescriptions: [],
            timeInterval: null
        })
    }

    setSocketStable = (new_socket_stable) => {
        this.setState({socketStable: new_socket_stable});
    }

    setPrevVists = (prev) => {
        this.setState({prevVisits: prev});
    };

    setPrevPrescriptions = (prev) => {
        this.setState({prevPrescriptions: prev});
    };

    setTimeInterval = (interval) => {
        this.setState({timeInterval: interval});
    }

    setSelectedIndex = (ind) => {
        this.setState({selectedIndex: ind});
    }

    setTodaysIndex = (ind) => {
        this.setState({todaysIndex: ind});
    }

    setMessages = (new_messages) => {
        this.setState({messages: new_messages});
    };

    addMessage = (message) => {
        var m = this.state.messages;
        m.push(message);
        this.setState({messages: m});
    }

    setCallLogs = (new_call_logs) => {
        this.setState({callLogs: new_call_logs});
    };


    setCallQueue = (new_call_queue) => {
        this.setState({callQueue: new_call_queue});
    };

    setIsOnline = (new_is_online) => {
        this.setState({isOnline: new_is_online});
    }

    addCallLog = (call_log) => {
        var m = this.state.callLogs;
        m.push(call_log);
        this.setState({callLogs: m});
    }

    addCallQueue = (call_queue) => {
        var m = this.state.callQueue;
        m.push(call_queue);
        this.setState({callQueue: m});
    }

    setInCall = (new_in_call) => {
        this.setState({inCall: new_in_call});
    }

    changePrescription = (presKey, presValue) => {
        var m = this.state.currentPrescription;
        m[presKey] = presValue;
        this.setState({currentPrescription: m});
    }

    setCurrentPrescription = (new_current_prescription) => {
        this.setState({currentPrescription: new_current_prescription});
    }

    setBackupPrescription = (new_current_prescription) => {
        this.setState({backupPrescription: new_current_prescription});
    }

    render() {
        return (
            <AppDataContext.Provider
                value={{
                    messages: this.state.messages,
                    setMessages: this.setMessages,
                    addMessage: this.addMessage,
                    callLogs: this.state.callLogs,
                    setCallLogs: this.setCallLogs,
                    callQueue: this.state.callQueue,
                    setCallQueue: this.setCallQueue,
                    isOnline: this.state.isOnline,
                    setIsOnline: this.setIsOnline,
                    addCallLog: this.addCallLog,
                    addCallQueue: this.addCallQueue,
                    inCall: this.state.inCall,
                    setInCall: this.setInCall,
                    currentPrescription: this.state.currentPrescription,
                    changePrescription: this.changePrescription,
                    setCurrentPrescription: this.setCurrentPrescription,
                    backupPrescription: this.state.backupPrescription,
                    setBackupPrescription: this.setBackupPrescription,
                    prevVisits: this.state.prevVisits,
                    setPrevVists: this.setPrevVists,
                    prevPrescriptions: this.state.prevPrescriptions,
                    setPrevPrescriptions: this.setPrevPrescriptions,
                    selectedIndex: this.state.selectedIndex,
                    setSelectedIndex: this.setSelectedIndex,
                    todaysIndex: this.state.todaysIndex,
                    setTodaysIndex: this.setTodaysIndex,
                    resetAll: this.resetAll,
                    timeInterval: this.state.timeInterval,
                    setTimeInterval: this.setTimeInterval,
                    socketStable: this.state.socketStable,
                    setSocketStable: this.setSocketStable
                }}
            >
                {this.props.children}
            </AppDataContext.Provider>
        );
    }
}

export {AppDataContext, AppDataProvider};
