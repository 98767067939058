import React, { useContext, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { deleteCookie, getCookie } from "../../js/cookies";
import { AppDataContext } from "../../context/AppContext";
import AdminHeader from "./panels/components/PanelMainHeader";
import {
  createCallLog,
  createPrescription,
  deleteCallQueue,
  getAllCallQueue,
  getLogInRecords,
  updateLogInRecords,
} from "../../js/convo";
import { auth } from "../../firebaseConfig";
import { SocketContext } from "../../context/socket";
import { HiMenuAlt1 } from "react-icons/hi";

const AdminPanel = () => {
  var url = useLocation().pathname;
  var nav = useNavigate();
  const [page, setPage] = useState("rooms");
  const [done, setDone] = useState(false);
  const [doctorProfile, setDoctorProfile] = useState({});
  const appContext = useContext(AppDataContext);

  const [isDoctorOnline, setIsDoctorOnline] = useState(false);

  const socket = useContext(SocketContext);

  const [windowWidth, setWindowWidth] = useState(window.screen.width);

  const setWindowDimensions = () => {
    setWindowWidth(window.screen.width);
  };
  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, [windowWidth]);

  useEffect(() => {
    if (url === "/") {
      nav("/callQueue");
      setPage("callQueue");
    }

    setPage(url.replace("/", ""));

    var doctor = getCookie("doctor");
    if (doctor !== "") {
      setDoctorProfile(JSON.parse(doctor));
    } else {
      logOutDoctor();
    }

    if (windowWidth < 800) {
      handleSideBar();
    }
  }, [url]);

  useEffect(() => {
    if (appContext.isOnline) {
      if (appContext.callQueue.length > 0) {
        if (!appContext.inCall) {
          document.getElementById("Audio").play();
        } else {
          document.getElementById("Audio").pause();
          document.getElementById("Audio").currentTime = 0;
        }
      } else {
        document.getElementById("Audio").pause();
        document.getElementById("Audio").currentTime = 0;
      }
    } else {
      document.getElementById("Audio").pause();
      document.getElementById("Audio").currentTime = 0;
    }
  }, [appContext.isOnline, appContext.callQueue]);

  const sendCallLog = async (map, doctor, callLog) => {
    var res = await getAllCallQueue();
    var calls = appContext.callQueue;
    if (res.callQueue) {
      calls = res.callQueue;
    } else {
      calls = [];
    }
    var delCall = calls.filter((call) => call.lm_reference_id === map.patient_id);
    var selCall = calls.filter((call) => call.lm_reference_id !== map.patient_id);
    if (delCall.length > 0) {
      var id = delCall[0].id;
      await deleteCallQueue(id);
    }
    appContext.setCallQueue(selCall);
    map.lm_reference_id = doctor.LM_REFERENCE_ID;
    map.doctor_id = doctor.LM_REFERENCE_ID;
    map.doctor_name = doctor.LM_NAME;
    map.lm_main_id = doctor.LM_MAIN_ID;
    map.lm_under_group = doctor.LM_UNDER_GROUP;
    callLog.push(map);
    appContext.setCallLogs(callLog);
    await createCallLog(map);
  };

  useEffect(() => {
    var doctor = getCookie("doctor");
    if (doctor !== "") {
      doctor = JSON.parse(doctor);
      socket.emit("signin", {
        dm_id: doctor.LM_REFERENCE_ID.toString(),
      });

      var prevId = 0;

      socket.on("missedCallToHardCodedRoom", (data) => {
        if (!data.toPatient) {
          console.log("missedCallToHardCodedRoom", data);
          if (data.call.id !== prevId) {
            prevId = data.call.id;
            console.log("Missed a call=================>");
            sendCallLog(data.call, doctor, appContext.callLogs);
          }
        }
      });
    }

    // var ss = 0;

    // socket.on("pong", (data)=>{
    //   ss = data.connection;
    // })

    // var int = setInterval(()=>{
    //   socket.emit("ping", {
    //     dm_id: doctor.LM_REFERENCE_ID.toString(),
    //     connection: true
    //   });
    // }, 60000);




    return function cleanUp(){
      socket.removeListener('missedCallToHardCodedRoom');
    }

  }, [socket]);

  const handleSideBar = () => {
    document.getElementById("side_bar").style.width = "0px";
  };

  const logOutDoctor = async () => {
    var doct = getCookie("doctor");
    if (doct !== "") {
      doct = JSON.parse(doct);
    }
    var res2 = await getLogInRecords(doct.LM_REFERENCE_ID);
    var d = new Date();
    var date = d.toLocaleDateString();
    var hours = d.getHours();
    var min = d.getMinutes();
    var sec = d.getSeconds();
    var full_dateTime = date + " " + hours + ":" + min + ":" + sec;
    var map = {
      logout_time: full_dateTime,
    };
    if (res2.data.length > 0) {
      updateLogInRecords(res2.data[res2.data.length - 1].id, map);
    }
    auth.signOut();
    // deleteCookie("doctor");
    // window.location.reload();
  };

  return (
    <section id="admin_panel">
      <AdminHeader doctorProfile={doctorProfile} />
      <div id="side_bar">
        <div style={{
          display: "flex",
        }}>
          <HiMenuAlt1
            style={{
              fontSize: "30px",
              cursor: "pointer",
              position: 'relative',
              color: '#fff',
              top: 15
            }}
            onClick={() => {
              document.getElementById("side_bar").style.width = "0";
              document.getElementsByClassName("panel_bar")[0].style.width = "100%";
            }}
            className="p-head-icon"
          />

          <div className="side_bar_header">
            <h1>Doctor's Panel</h1>
          </div>
        </div>

        <br />
        <div className="side_bar_content">
          <NavLink style={{ textDecoration: "none" }} to={"/callQueue"}>
            <div className={"list " + (page === "callQueue" ? "active" : "")}>
              <h5>Call Queue</h5>
            </div>
          </NavLink>

          <NavLink style={{ textDecoration: "none" }} to={"/callHistory"}>
            <div className={"list " + (page === "callHistory" ? "active" : "")}>
              <h5>Call History</h5>
            </div>
          </NavLink>

          <NavLink style={{ textDecoration: "none" }} to={"/logInOutRegister"}>
            <div
              className={
                "list " + (page === "logInOutRegister" ? "active" : "")
              }
            >
              <h5>Log In/ Out Register</h5>
            </div>
          </NavLink>

          <NavLink
            onClick={() => {
              window.open(
                "https://sites.google.com/view/liveopdprivacypolicy/home",
                "_blank"
              );
            }}
            style={{ textDecoration: "none" }}
            to={""}
          >
            <div className={"list " + (page === "demo" ? "active" : "")}>
              <h5>Privacy Policy</h5>
            </div>
          </NavLink>

          <NavLink
            onClick={() => {
              window.open(
                "https://sites.google.com/view/liveopdtermscondiitions/home",
                "_blank"
              );
            }}
            style={{ textDecoration: "none" }}
            to={""}
          >
            <div className={"list " + (page === "demo" ? "active" : "")}>
              <h5>Terms & Conditions</h5>
            </div>
          </NavLink>
        </div>
      </div>

      <div className="panel_bar">
        <Outlet />
      </div>
    </section>
  );
};
export default AdminPanel;
